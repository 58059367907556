import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Image,
  Stack,
  Text,
  Link,
  Spinner,
  useToast,
  Heading,
  Divider,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { useAuth } from "../contexts/auth.context";
import { Navigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const toast = useToast();

  const { isLoading, signIn, isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container maxW="md" py={{ base: "12", md: "24" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Image src={"../TMA_Logo.png"} />
          </Stack>
        </Stack>
        <VStack>
          <Heading size="lg" textAlign="center">
            Patient Portal
          </Heading>
          <Divider width="60%" justifySelf="center" />
        </VStack>
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                disabled={isLoading}
                id="email"
                placeholder="Enter your email"
                type="email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                disabled={isLoading}
                id="password"
                placeholder="********"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Stack>
          <HStack justify="space-between">
            <Checkbox defaultChecked>Remember me</Checkbox>
            <Button
              disabled={isLoading}
              onClick={() => window.location.replace("/password-reset")}
              variant="link"
              colorScheme="brand"
              size="sm"
            >
              Forgot password
            </Button>
          </HStack>
          <Stack spacing="4">
            <Button
              disabled={isLoading}
              colorScheme="blue"
              onClick={() => {
                signIn(username, password).then(({ success, message }) => {
                  const status = success ? "success" : "error";
                  toast({ position: "top-right", title: message, status });
                });
              }}
            >
              {isLoading ? (
                <span>
                  <Spinner />
                  Signing in...
                </span>
              ) : (
                "Sign in"
              )}
            </Button>
          </Stack>
        </Stack>
        <VStack spacing="1" justify="center">
          <Text fontSize="sm" color="muted">
            Don't have an account?
          </Text>
          <Link colorScheme="brand" size="sm" href="/signup">
            Sign up as patient
          </Link>

          <Link colorScheme="brand" size="sm" href="/care-team-member/signup">
            Sign up as care team member
          </Link>
        </VStack>
      </Stack>
    </Container>
  );
};

export default Login;
