import React from "react";
import { useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import Chat from "src/components/Chat";
import { getArticles } from "src/services/doc-one/insight";
import Loader from "src/components/Loader";
import EmptyState from "src/components/EmptyState";
import ReactPaginate from "react-paginate";
import { usePatientContext } from "src/contexts/patient.context";

const Instructions = () => {
  return (
    <Box display="grid" placeItems="center" padding="50px 100px" maxW="700px" margin="0 auto">
      <Heading as="h2" size="lg" color="gray.600" marginBottom="1rem">
        Freyja Insight
      </Heading>

      <Text marginBottom="10">Your AI co-pilot for biomedical insight</Text>

      <List spacing={5}>
        <ListItem>
          <Heading as="h3" size="md" color="gray.600">
            <ListIcon as={MdCheckCircle} color="green.500" /> Search
          </Heading>
          <Box as="p" marginLeft="8">
            Enter topics (separated by semicolons) into the search box to find relevant articles.
          </Box>
        </ListItem>
        <ListItem>
          <Heading as="h3" size="md" color="gray.600">
            <ListIcon as={MdCheckCircle} color="green.500" /> Select
          </Heading>
          <Box as="p" marginLeft="8">
            Select 1-5 articles (check the box) for the chat to use in responding to your queries.
            The top 5 are the default choice.
          </Box>
        </ListItem>
        <ListItem>
          <Heading as="h3" size="md" color="gray.600">
            <ListIcon as={MdCheckCircle} color="green.500" /> Chat
          </Heading>
          <Box as="p" marginLeft="8">
            The chat is ready for use. For best results, choose from the pre-defined options instead
            of typing messages.The chat is ready for use. For best results, choose from the
            pre-defined options instead of typing messages.
          </Box>
        </ListItem>
      </List>
    </Box>
  );
};

export default function Insight() {
  const { patientData } = usePatientContext();
  const [loading, setLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [articles, setArticles] = useState<any>([]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const keywords: string[] = [];

  if (patientData?.mainDiagnosis) {
    keywords.push(patientData.mainDiagnosis);
    const diagnosisParts = patientData.mainDiagnosis.replaceAll("-", " ").split(" ");
    if (diagnosisParts.length > 1) {
      keywords.push(...diagnosisParts);
    }
  }

  const hasNoSearchResult = searchPerformed && !articles.length;

  const performSearch = async ({ query, page = 1, size = 5 }) => {
    try {
      setLoading(true);
      const response = await getArticles({ query, page, size });
      setSearchPerformed(true);
      setArticles(response.data.map((article) => ({ ...article, selected: true })));
      setPagesCount(response.pages);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert("An error happened. Try again later");
    }
  };

  const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const formDataObject = Object.fromEntries(formData.entries());
    const value = formDataObject.search as string;
    if (!value?.length) return;
    performSearch({ query: value.trim() });
  };

  const handlePageClick = (event) => {
    performSearch({
      query: inputRef.current?.value,
      page: event.selected + 1,
    });
  };

  return (
    <>
      <Box as="form" position="relative" width="full" mb="8px" onSubmit={handleSearch}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.500" />}
            paddingLeft="3"
          />
          <Input
            isDisabled={loading}
            ref={inputRef}
            name="search"
            boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
            borderRadius="100px"
            backgroundColor="white"
            color="gray.900"
            fontSize="sm"
            focusBorderColor="blue.500"
            width="full"
            paddingLeft="10"
            paddingTop="2.5"
            paddingBottom="2.5"
            placeholder={`Enter topics separated by semicolons (example: Ad5; PSA; brachyury; MUC-1; prostate cancer)`}
          />
        </InputGroup>
      </Box>

      <Stack direction="row" mb="2">
        {keywords.map((keyword, index) => (
          <Badge
            key={index}
            padding="2"
            borderRadius="100px"
            colorScheme="green"
            onClick={() => {
              if (inputRef.current) {
                const value = keyword.replaceAll("-", " ");
                const term = inputRef.current.value + " " + value;
                inputRef.current.value = term;
                inputRef.current?.setSelectionRange(term.length, term.length);
                inputRef.current?.focus();
              }
            }}
          >
            {keyword}
          </Badge>
        ))}
      </Stack>

      <Flex>
        <Box
          flex="1"
          border="1px solid lightgray"
          padding="10px"
          maxHeight="90vh"
          overflow="scroll"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            py={3}
            borderBottom="2px"
            borderColor="gray.200"
          >
            {searchPerformed ? (
              <>
                <Box fontSize="2xl" mt={1} color="gray.700">
                  Articles
                </Box>
              </>
            ) : null}
          </Flex>

          <HStack justifyContent="center">
            <ReactPaginate
              className="react-pagination-container"
              activeClassName="active-link"
              breakLabel="..."
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pagesCount}
              previousLabel="< Previous"
              renderOnZeroPageCount={null}
            />
          </HStack>

          {loading && (
            <Box>
              <Loader />
            </Box>
          )}

          {hasNoSearchResult && !loading ? (
            <EmptyState
              header="No results found"
              description="Try searching for something else."
              icon={<SearchIcon boxSize="3rem" color="gray.300" />}
            />
          ) : null}

          {!searchPerformed && !loading ? <Instructions /> : null}

          {!loading && (
            <VStack align="left">
              {articles.map((article: any) => (
                <Flex key={article.pmid}>
                  <Box background="brand.gray3">
                    <input
                      name={article.pmid}
                      id={article.pmid}
                      checked={article.selected}
                      onChange={(event) => {
                        const newArticles = articles.map((art) => {
                          if (art.pmid === article.pmid) {
                            return { ...art, selected: event.target.checked };
                          }
                          return art;
                        });
                        setArticles(newArticles);
                      }}
                      type="checkbox"
                    />
                  </Box>
                  <Box background="white" padding="16px" flex="1">
                    <Heading
                      size="sm"
                      color="blue.700"
                      as="a"
                      href={article.url}
                      target="_blank"
                      cursor="pointer"
                      _hover={{ textDecoration: "underline", color: "blue.600" }}
                    >
                      {article.title}
                    </Heading>
                    <Text color="gray.500">
                      <strong>Publication year: </strong> {article.publication_year}
                    </Text>
                    <Text color="gray.500">
                      <strong>Journal: </strong> {article.journal}
                    </Text>
                    <Text color="gray.500">
                      <strong>Authors: </strong>
                      {article.authors
                        ?.map((author: any) => author.first_name || author.last_name)
                        .join(", ") || "N/A"}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </VStack>
          )}
        </Box>

        {searchPerformed ? <Chat articles={articles} /> : null}
      </Flex>
    </>
  );
}
