import React, { ChangeEvent } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
} from "@chakra-ui/react";
import { useAuth } from "../contexts/auth.context";
import FileUpload from "../components/FileUpload";
import AddressForm, { AddressInfo } from "../components/AddressForm";
import { fileToBase64 } from "../utils";
import { updatePatient, uploadPatientAvatar, usePatientByUserId } from "src/services/patient";
import Loader from "src/components/Loader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { usePatientContext } from "src/contexts/patient.context";
import { useParams } from "react-router-dom";

const Settings: React.FC = () => {
  const { user } = useAuth();
  const patientContext = usePatientContext();
  const params = useParams();
  const patientUserId = params.patientId as string;
  const { data: patient, isLoading } = usePatientByUserId(patientUserId);

  const [avatarBase64, setAvatarBase64] = React.useState<string | null>(null);
  const [patientData, setPatientData] = React.useState(patient);

  const toast = useToast();

  const queryClient = useQueryClient();
  const patientMutation = useMutation(updatePatient, {
    onSuccess: (response) => {
      queryClient.setQueryData(["patientByUserId", patientUserId], response.data);
      patientContext.setPatientData(response.data);
      toast({
        position: "top-right",
        status: "success",
        title: "Profile successfully updated!",
      });
    },
    onError: (err) => {
      console.error(err);
      toast({
        position: "top-right",
        status: "error",
        title: "Profile update failed",
      });
    },
  });

  const loading = patientMutation.isLoading || isLoading;

  if (!patientData && patient) {
    setPatientData(patient);
  }

  const onUpdateProfile = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updateObject = {
      ...patient,
      ...patientData,
      phoneNumber: formatPhone(patientData?.phoneNumber || ""),
      birthDate: new Date(patientData?.birthDate).toISOString(),
      address: {
        ...patientData?.address,
        ...patientData.address,
      },
    };
    patientMutation.mutate({ patientId: patient.id, data: updateObject });
  };

  const handleFileSelect = (file: File) => {
    fileToBase64(file).then((base64: string) => {
      uploadPatientAvatar({ avatarBase64: base64, userId: user.username })
        .then((response) => {
          setAvatarBase64(base64);
          queryClient.setQueryData(["patientByUserId", patient.userId], response.data);
          patientContext.setPatientData(response.data);
          toast({
            position: "top-right",
            status: "success",
            title: "Avatar uploaded correctly",
          });
        })
        .catch((err) => {
          toast({
            position: "top-right",
            status: "error",
            title: err?.message || "File upload failed",
          });
        });
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box
        rounded={"lg"}
        width={"100%"}
        bg={"white"}
        px={"6px"}
        py={{ base: "4", md: "8" }}
        maxW={"800"}
        id="settings-form"
      >
        <Stack spacing="5" p={10}>
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              {patient?.name + " " + patient?.familyName}
            </Text>
          </Box>
          <Divider />
          <Stack spacing={{ base: "3", md: "5" }} width="full" maxW={{ md: "3xl" }}>
            <Avatar
              size="xl"
              name={patient?.name + " " + patient?.familyName}
              src={avatarBase64 || patientData?.avatarUrl}
            />
            <FileUpload
              onFileSelect={handleFileSelect}
              accept="image/png, image/gif, image/jpeg"
              buttonText="Upload Image"
            />
          </Stack>
          <Divider />
          <form onSubmit={onUpdateProfile}>
            <Flex direction="column" gap="6" margin="0 auto" p={4}>
              <Flex gap="4" direction={["column", "column", "row"]}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    disabled={loading}
                    value={patientData?.name}
                    onChange={(e) =>
                      setPatientData({
                        ...patient,
                        ...patientData,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Family Name</FormLabel>
                  <Input
                    type="text"
                    disabled={loading}
                    value={patientData?.familyName}
                    onChange={(e) =>
                      setPatientData({
                        ...patient,
                        ...patientData,
                        familyName: e.target.value,
                      })
                    }
                    required
                  />
                </FormControl>
              </Flex>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  disabled
                  type="email"
                  value={patientData?.email}
                  onChange={(e) =>
                    setPatientData({
                      ...patient,
                      ...patientData,
                      email: e.target.value,
                    })
                  }
                  required
                />
              </FormControl>

              <FormControl>
                <FormLabel>Birth Date</FormLabel>
                <Input
                  disabled={loading}
                  placeholder="Select birth date"
                  size="md"
                  type="date"
                  value={moment(patientData?.birthDate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setPatientData({
                      ...patient,
                      ...patientData,
                      birthDate: e.target.value,
                    })
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  disabled={loading}
                  placeholder="+15545855955"
                  size="md"
                  value={patientData?.phoneNumber}
                  onChange={(e) =>
                    setPatientData({
                      ...patient,
                      ...patientData,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </FormControl>

              <AddressForm
                disabled={loading}
                addressInfo={patientData?.address}
                onChange={(addressObj: AddressInfo) =>
                  setPatientData({
                    ...patient,
                    ...patientData,
                    address: addressObj,
                  })
                }
              />
            </Flex>

            <Stack>
              <Flex direction="row-reverse">
                <Button type="submit" colorScheme="blue" disabled={loading}>
                  {loading ? "Updating..." : "Update Profile"}
                </Button>
              </Flex>
            </Stack>
          </form>
        </Stack>
      </Box>
    </>
  );
};

function formatPhone(phone: string) {
  if (!phone.length) return "";
  if (!phone.startsWith("+")) return `+${phone}`;
  return phone;
}

export default Settings;
