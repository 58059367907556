import axios from 'axios';

export class OpenFDA {

  APIEndpoint: string;


  constructor() {
    this.APIEndpoint = "https://api.fda.gov/drug/drugsfda.json?search=";
  }

  async SearchName(name: string) {
    const data = {
      "openfda.name": name,
    };

    function extractData(results: any) {
      // if (results && results.length > 0) {
      const data = results[0];
      const { openfda } = data;

      return {
        route: openfda.route[0] || "",
        brandName: openfda.brand_name[0] || "",
        genericName: openfda.generic_name[0] || "",
        pharmClassEPC: openfda.pharm_class_epc ? openfda.pharm_class_epc[0] : "",
        pharmClassPE: openfda.pharm_class_pe ? openfda.pharm_class_pe[0] : "",
        manufacturerName: openfda.manufacturer_name[0] || "",
      };
      // }
      // return {};
    }

    const searchParams = new URLSearchParams(data);
    return axios.get(this.APIEndpoint+searchParams.toString())
      .then((response) => {
        return extractData(response.data.results);
      })
      .catch((error) => {
        console.error(error);
        return {
          route: "",
          brandName: "",
          genericName: "",
          pharmClassEPC: "",
          pharmClassPE: "",
          manufacturerName: "",
        };
      });
  }

}
