import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Select,
  VStack,
} from "@chakra-ui/react";
import ActionButton from "./ActionButton";
import { getBase64, sanitizeFilename } from "../../../utils";
import FileUploadInput from "../FileUploadInput";
import { ChangeEvent, useState } from "react";
import { uploadFileToCase } from "src/services/patient";
import { usePatientContext } from "src/contexts/patient.context";
import { fileTypes } from "src/constants/common";

const AddFileDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filename, setFilename] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { refetchCase, caseData } = usePatientContext();
  const [fileType, setFileType] = useState<string>("");

  const toast = useToast({ position: "top-right" });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetState();
    const files = e.target.files || null;
    const file = files?.[0] || null;
    setFilename(sanitizeFilename(file?.name || ""));
    setSelectedFile(file);
  };

  const resetState = () => {
    setFilename("");
    setSelectedFile(null);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast({ status: "error", description: "Please select a file to upload" });
      return;
    }
    if (!fileType) {
      toast({ status: "error", description: "Please select a file type" });
      return;
    }

    const file = selectedFile;
    const pdf_blob = await getBase64(file);

    try {
      setLoading(true);
      uploadFileToCase({
        fileBase64: pdf_blob,
        fileName: filename,
        fileType: fileType,
        caseId: caseData?.id,
      })
        .then((response) => {
          toast({ status: "success", description: "File uploaded successfully!" });
          refetchCase();
          onClose();
        })
        .catch(() => {
          toast({
            status: "error",
            description: "Error! Failed to upload file to case. Please try again",
          });
        });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: "Error! Failed to upload file to case. Please try again",
      });
    }
  };

  return (
    <>
      <ActionButton onClick={() => onOpen()} title={"Add File"} logoSrc={"/frame-411.svg"} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Upload a File</DrawerHeader>

          <DrawerBody>
            <VStack gap="4">
              <FileUploadInput
                disabled={false}
                title="Select file to upload"
                accept=".pdf"
                onInputClear={resetState}
                onChange={handleOnChange}
              />

              <Select
                placeholder="Select file type"
                onChange={(e) => setFileType(e.target.value)}
                value={fileType}
              >
                {fileTypes.map((fileType) => (
                  <option value={fileType.value}>{fileType.label}</option>
                ))}
              </Select>
              <Button
                isLoading={loading}
                loadingText="Uploading"
                onClick={handleFileUpload}
                colorScheme="facebook"
                width="100%"
                disabled={!selectedFile}
              >
                Upload File
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddFileDrawer;
