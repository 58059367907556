import { createContext, useContext, useMemo, useState } from "react";
import { Step } from "react-joyride";

export interface TourState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

const initialTourState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const tourContext = createContext({
  state: initialTourState,
  setState: (arg: Partial<TourState>) => {},
});

export function TourProvider(props: any) {
  const [state, setState] = useState(initialTourState);

  const value = useMemo(
    () => ({
      state,
      setState: (arg: any) => {
        setState({ ...state, ...arg });
      },
    }),
    [setState, state]
  );

  return (
    <tourContext.Provider value={value} {...props}>
      {props.children}
    </tourContext.Provider>
  );
}

export function useTourContext() {
  const context = useContext(tourContext);

  if (!context) {
    throw new Error("TourContext must be used within a Provider");
  }

  return context;
}
