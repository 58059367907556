import React, { createContext, useState } from "react";
import { useParams } from "react-router-dom";
import { usePatientByUserId, usePatientCase } from "src/services/patient";

const PatientContext = createContext();

function PatientContextProvider({ children }) {
  const [patientData, setPatientData] = useState(null);
  const [caseData, setCaseData] = useState(null);

  const params = useParams();
  const patient = usePatientByUserId(params.patientId, {
    onSuccess: (data) => {
      setPatientData(data);
    },
  });
  const patientCase = usePatientCase(patient?.data?.caseId, {
    onSuccess: (data) => {
      setCaseData(data);
    },
  });

  const { refetch: refetchPatient } = patientCase;
  const { refetch: refetchCase } = patientCase;

  const loading = patient.isLoading || patientCase.isLoading;
  const error = patient.isError || patientCase.isError;

  if (patient.data && !patientData) {
    setPatientData(patient.data);
  }

  if (patientCase.data && !caseData) {
    setCaseData(patientCase.data);
  }

  const value = {
    patientData,
    setPatientData,
    refetchPatient,
    refetchCase,
    caseData,
    setCaseData,
    loading,
    error,
  };

  return <PatientContext.Provider value={value}>{children}</PatientContext.Provider>;
}

function usePatientContext() {
  const context = React.useContext(PatientContext);
  if (context === undefined) {
    throw new Error("usePatientContext must be used within a AppContextProvider");
  }
  return context;
}

export { PatientContextProvider, usePatientContext };
