import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Buffer } from "buffer";

const API_BASE_URL = process.env.REACT_APP_RARECARE_API || "https://api.rarecare.com";

export async function getPatientByUserId(userID: string) {
  const endpoint = API_BASE_URL + `/patients/by-user-id/${userID}`;
  return axios.get(endpoint).then((res) => res.data);
}

export async function getPatientCase(caseId: string) {
  const endpoint = API_BASE_URL + `/cases/${caseId}`;
  return axios.get(endpoint).then((res) => res.data);
}

export async function getPatientCareTeam(userId: string) {
  const endpoint = API_BASE_URL + `/patients/${userId}/care-team-members`;
  return axios.get(endpoint).then((res) => res.data);
}

export async function removeCareTeamMemberFromPatient({ patientId, careTeamMemberId }: any) {
  const endpoint = API_BASE_URL + `/patients/remove-care-team-member`;
  return axios
    .post(endpoint, {
      patientId,
      careTeamMemberId,
    })
    .then((res) => res.data);
}

export async function uploadPatientAvatar(data: { avatarBase64: string; userId: string }) {
  return axios.post(API_BASE_URL + "/patients/upload-avatar", data);
}

export async function updatePatient({ patientId, data }: any) {
  return axios.put(API_BASE_URL + `/patients/${patientId}`, data);
}

export async function registerPatient(data: any) {
  const endpoint = API_BASE_URL + "/patients/register";
  return axios.post(endpoint, data).then(({ data }: any) => {
    return data;
  });
}

export function usePatientByUserId(userId: string, options?: any) {
  return useQuery(["patientByUserId", userId], () => getPatientByUserId(userId), {
    ...options,
    enabled: options?.enabled ?? Boolean(userId),
  });
}

export function usePatientCase(caseId: string, options?: any) {
  return useQuery(["patientCase", caseId], () => getPatientCase(caseId), {
    enabled: Boolean(caseId),
    ...options,
  });
}

// Case service
export async function uploadFileToCase(data: any) {
  return axios.post(API_BASE_URL + "/cases/upload-file", {
    caseId: data.caseId,
    fileName: data.fileName,
    fileBase64: data.fileBase64,
    fileType: data.fileType,
  });
}

export async function downloadCaseFile({ fileName, caseId }) {
  return axios
    .post(API_BASE_URL + "/case/files/download", {
      CaseID: caseId,
      Filename: fileName,
    })
    .then((res: any) => {
      return Buffer.from(res.data.Payload, "base64");
    });
}

export async function updateCase({ caseId, data }: any) {
  return axios.put(API_BASE_URL + `/cases/${caseId}`, data);
}

export async function getCaseFileLabels({ caseId, fileName }: any) {
  return axios.post(API_BASE_URL + "/emr/file/labels/get", {
    CaseID: caseId,
    Filename: fileName,
  });
}

export async function searchCaseFileLabels({ keywords, caseId }: any) {
  return axios.post(API_BASE_URL + "/case/search", {
    CaseID: caseId,
    Keywords: keywords
      .split(" ")
      .map((k) => k.trim())
      .filter(Boolean),
  });
}
