import { Routes, Route, Navigate } from "react-router-dom";

import Loader from "../components/Loader";
import PatientDashboard from "../pages/patient-dashboard.page";
import CareTeamDashboard from "../pages/care-team-dashboard.page";
import Login from "../pages/login.page";
import Settings from "../pages/settings.page";
import { useAuth } from "../contexts/auth.context";
import MyFiles from "src/pages/my-files.page";
import MyMedicationsPage from "src/pages/my-medications.page";
import ForgotPasswordForm from "src/pages/password-reset.page";
import { Link } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import Insight from "src/pages/insight.page";
import TermsAndConditions from "src/pages/terms-and-conditions.page";
import ConsentForm from "src/pages/consent-form.page";
import SignupPage from "src/pages/patient-signup.page";
import PhysicianSearch from "src/pages/physician-search.page";
import Layout from "src/components/Layout";
import Home from "src/pages/home.page";
import CareTeamMemberSignup from "src/pages/care-team-member-signup.page";
import CareTeamInvites from "src/pages/care-team-invites.page";
import PatientCareTeam from "src/pages/patient-care-team.page";

export interface RouterType {
  title: string;
  path?: string;
  element: React.FunctionComponent;
  index?: boolean;
  isProtected: boolean;
  roles?: string[];
}

const routes: RouterType[] = [
  {
    title: "Home",
    path: "/",
    isProtected: false,
    element: Home,
  },
  {
    title: "Patient Dashboard",
    path: "patient/:patientId/dashboard",
    isProtected: true,
    // roles: ["patient", "care-team-member"],
    element: () => (
      <Layout>
        <PatientDashboard />
      </Layout>
    ),
  },
  {
    title: "Insight",
    path: "patient/:patientId/insight",
    isProtected: false,
    // roles: ["patient", "care-team-member"],
    element: () => (
      <Layout>
        <Insight />
      </Layout>
    ),
  },
  {
    title: "My files",
    path: "patient/:patientId/files",
    isProtected: true,
    element: () => (
      <Layout>
        <MyFiles />
      </Layout>
    ),
  },
  {
    title: "My medications",
    path: "patient/:patientId/medications",
    isProtected: true,
    element: () => (
      <Layout>
        <MyMedicationsPage />
      </Layout>
    ),
  },
  {
    title: "Settings",
    path: "patient/:patientId/settings",
    isProtected: true,
    element: () => (
      <Layout>
        <Settings />
      </Layout>
    ),
  },
  {
    title: "Care Team Members",
    path: "patient/:patientId/care-team-members",
    isProtected: true,
    element: () => (
      <Layout>
        <PatientCareTeam />
      </Layout>
    ),
  },
  {
    title: "Physician Search",
    path: "patient/:patientId/physician-search",
    isProtected: false,
    // roles: ["care-team-member"],
    element: PhysicianSearch,
  },
  {
    title: "Care Team Dashboard",
    path: "care-team-member/:ctmId/dashboard",
    isProtected: true,
    roles: ["care-team-member"],
    element: CareTeamDashboard,
  },
  {
    title: "Care Team Invites",
    path: "care-team-member/:ctmId/invites",
    isProtected: true,
    roles: ["care-team-member"],
    element: CareTeamInvites,
  },
  {
    title: "Login",
    path: "/login",
    element: Login,
    isProtected: false,
  },
  {
    title: "Signup",
    path: "signup",
    element: SignupPage,
    isProtected: false,
  },
  {
    title: "Care Team Member Signup",
    path: "care-team-member/signup",
    element: CareTeamMemberSignup,
    isProtected: false,
  },
  {
    title: "Forgot password",
    path: "password-reset",
    element: ForgotPasswordForm,
    isProtected: false,
  },
  {
    title: "Terms & Conditions",
    path: "terms-and-conditions",
    element: TermsAndConditions,
    isProtected: false,
  },
  {
    title: "Consent form",
    path: "consent-form",
    element: ConsentForm,
    isProtected: false,
  },
  {
    title: "Page not found",
    path: "*",
    element: () => <div>Page not found.</div>,
    isProtected: false,
  },
];

const ProtectedRoute = (props: React.PropsWithChildren<{ routeData: RouterType }>) => {
  const { isLoading, isAuthenticated, user } = useAuth();
  const { roles, isProtected } = props.routeData;

  if (!isProtected) return <>{props.children}</>;

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (roles && !roles.includes(user.role as string)) {
    return (
      <Box w="100vw" h="100vh" display="grid" placeItems="center">
        <Box textAlign="center">
          <Text fontSize="26px">Not authorized!</Text>
          <Link to={`/`}> ← Go back</Link>
        </Box>
      </Box>
    );
  }

  return <>{props.children}</>;
};

const Router: React.FunctionComponent = () => {
  return (
    <Routes>
      {routes.map((route) => {
        const Page = route.element;
        return (
          <Route
            key={route.title}
            path={route.path}
            element={
              <ProtectedRoute routeData={route}>
                <Page />
              </ProtectedRoute>
            }
          />
        );
      })}
    </Routes>
  );
};

export default Router;
