import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { usePatientContext } from "src/contexts/patient.context";
import { uploadFileToCase } from "src/services/patient";
import { signPDF } from "src/utils/pdf-helper";
import SigningForm from "../SigningForm";

const PatientConsentPDFSigner = ({ onSignComplete }) => {
  const { caseData, setCaseData, refetchPatient } = usePatientContext();
  const [pdfBytes, setPdfBytes] = useState(null);

  const handleSign = async (userName) => {
    const signedPdfBytes = await signPDF(pdfBytes, userName);
    const blob = new Blob([signedPdfBytes], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);

    const pdfBase64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    uploadFileToCase({
      fileBase64: pdfBase64,
      fileName: "consent.pdf",
      fileType: "consent_form",
      caseId: caseData?.id,
    })
      .then((response) => {
        setCaseData((prev) => ({ ...prev, files: response.data.files }));
        refetchPatient();
        onSignComplete();
        window.open(blobUrl);
      })
      .catch(() => {
        alert("Failed to upload consent form to case. Try again!");
      });
  };

  useEffect(() => {
    async function getPdfBytes(pdfUrl) {
      const response = await fetch(pdfUrl);
      const arrayBuffer = await response.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      return uint8Array;
    }
    getPdfBytes("/consent.pdf").then((pdfBytes) => {
      setPdfBytes(pdfBytes);
    });
  }, []);

  return (
    <Box width="100%">
      <SigningForm onSign={handleSign} />
      <Box marginTop="4" height="75vh">
        <iframe src="/consent.pdf" width="100%" height="100%" title="Consent Form" />
      </Box>
    </Box>
  );
};

export default PatientConsentPDFSigner;
