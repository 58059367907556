import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export default function CareTeamInviteModal({ isOpen, onClose, onSubmit }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite a Care Team Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" id="care-team-invite-form" onSubmit={onSubmit}>
          <FormControl>
            <FormLabel required>Email Address</FormLabel>
            <Input required type="email" name="email" placeholder="Type email address here" />
            <FormHelperText>The email of the care team member you want to invite</FormHelperText>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit" form="care-team-invite-form" mr={3}>
            Send Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
