import { Box, HStack, IconButton, Text, Tooltip, VStack } from "@chakra-ui/react";
import { PDFPage } from "pdf-lib";
import { useState } from "react";
import { FiArrowLeftCircle, FiArrowRightCircle, FiZoomIn, FiZoomOut } from "react-icons/fi";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

type Props = {
  page: number;
  fileName: string;
  fileBytes: Uint8Array;
  pages: PDFPage[];
};

export default function PDFViewer(props: Props) {
  const [currentPage, setCurrentPage] = useState(props.page);
  const [scale, setScale] = useState(1);

  const { fileBytes, pages } = props;

  const hasNextPage = currentPage < pages.length;
  const hasPreviousPage = currentPage > 1;

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomReset = () => {
    setScale(1);
  };

  return (
    <VStack maxWidth="100%">
      <HStack marginTop="4px">
        <Tooltip label="zoom out" hasArrow>
          <IconButton
            variant="ghost"
            aria-label="Zoom out"
            icon={<FiZoomOut size="24" />}
            onClick={handleZoomOut}
          />
        </Tooltip>
        <Tooltip label="reset zoom" hasArrow>
          <IconButton
            variant="ghost"
            aria-label="Zoom out"
            icon={<MdOutlineZoomOutMap size="24" />}
            onClick={handleZoomReset}
          />
        </Tooltip>
        <Tooltip label="zoom in" hasArrow>
          <IconButton
            variant="ghost"
            aria-label="Zoom in"
            icon={<FiZoomIn size="24" />}
            onClick={handleZoomIn}
          />
        </Tooltip>
      </HStack>

      <Box
        // border="2px solid gray"
        height="800px"
        maxHeight="100%"
        maxWidth="100%"
        overflow="scroll"
        padding="8px"
      >
        <Document file={{ data: fileBytes }}>
          <Page pageNumber={currentPage} scale={scale} />
        </Document>
      </Box>
      <HStack marginTop="8px">
        <IconButton
          variant="ghost"
          aria-label="Previous pdf file page"
          icon={<FiArrowLeftCircle />}
          isDisabled={!hasPreviousPage}
          onClick={handlePreviousPage}
        />
        <Text>
          Page {currentPage} of {pages.length}
        </Text>
        <IconButton
          variant="ghost"
          aria-label="Next pdf file page"
          icon={<FiArrowRightCircle />}
          isDisabled={!hasNextPage}
          onClick={handleNextPage}
        />
      </HStack>
    </VStack>
  );
}
