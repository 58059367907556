import { useToast } from "@chakra-ui/react";
import ActionButton from "./ActionButton";

export default function DownloadReport() {
  const toast = useToast();

  const handleDownloadReport = () => {
    toast({
      position: "top-right",
      title: "Report Download Started",
      status: "info",
    });
    // RareCare?.downloadFile("PharmacoGenomicsReport.pdf").then((data) => {
    //   const a = document.createElement("a");
    //   a.style.display = "none";
    //   document.body.appendChild(a);
    //   const blobFile = new Blob([data]);
    //   console.log(blobFile);
    //   const url = window.URL.createObjectURL(blobFile);
    //   a.href = url;
    //   a.download = "PharmacoGenomicsReport.pdf";
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // });
  };

  return (
    <ActionButton
      // width="100%"
      onClick={handleDownloadReport}
      title={"Download Report"}
      logoSrc={"/frame-413.svg"}
    />
  );
}
