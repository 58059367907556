import { ChangeEvent } from "react";
import {
  Button,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer,
  DrawerFooter,
  FormControl,
  FormLabel,
  FormHelperText,
  useToast,
} from "@chakra-ui/react";

import Loader from "src/components/Loader";
import VirtualizedSelect from "src/components/VirtualizedSelect";
import diagnosisJson from "src/utils/data/diagnosis.data.json";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updatePatient } from "src/services/patient";
import { usePatientContext } from "src/contexts/patient.context";

const diagnosisOptions = diagnosisJson
  .map((v) => ({ label: v.label, value: v.label }))
  .sort((a, b) => a.label.localeCompare(b.label));

interface DiagnosisDrawerProps {
  initialValue: string;
  isOpen: boolean;
  patient: any;
  onClose: () => void;
}

export default function DiagnosisEditDrawer({
  initialValue,
  patient,
  isOpen,
  onClose: closeDrawer,
}: DiagnosisDrawerProps) {
  const toast = useToast({ position: "top-right" });
  const patientContext = usePatientContext();

  const queryClient = useQueryClient();
  const patientMutation = useMutation(updatePatient, {
    onSuccess: (response) => {
      queryClient.setQueryData(["patientByUserId", patient.userId], response.data);
      patientContext.setPatientData(response.data);
      closeDrawer();
      toast({
        position: "top-right",
        status: "success",
        title: "Profile successfully updated!",
      });
    },
    onError: (err) => {
      console.error(err);
      toast({
        position: "top-right",
        status: "error",
        title: "Profile update failed",
      });
    },
  });

  const updating = patientMutation.isLoading;

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());
    const diagnosis = formDataObject.diagnosis as string;

    patientMutation.mutate({
      patientId: patient.id,
      data: {
        mainDiagnosis: diagnosis,
      },
    });
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit or Add Diagnosis</DrawerHeader>

        {updating ? <Loader /> : null}

        <form onSubmit={handleSubmit}>
          <DrawerBody minHeight="400px">
            <FormControl>
              <FormLabel>Diagnosis *</FormLabel>
              <VirtualizedSelect
                name="diagnosis"
                defaultValue={initialValue ? { label: initialValue, value: initialValue } : null}
                options={diagnosisOptions}
                required
                placeholder="Select or search diagnosis.."
                isDisabled={updating}
              />
              <FormHelperText color="brand.gray2">
                <em>Select or search diagnosis.</em>
              </FormHelperText>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeDrawer} disabled={updating}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" disabled={updating}>
              Save
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
