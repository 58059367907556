import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

export const signPDF = async (pdfBytes, userName) => {
  const pdfDoc = await PDFDocument.load(pdfBytes);
  pdfDoc.registerFontkit(fontkit);
  const pages = pdfDoc.getPages();
  const lastPage = pages[pages.length - 1]; // Get the last page

  const url = "https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf";
  const fontBytes = await fetch(url).then((res) => res.arrayBuffer());
  const ubuntuFont = await pdfDoc.embedFont(fontBytes);

  lastPage.drawText("Signed on: " + new Date().toUTCString(), {
    x: 50,
    y: 125,
    size: 12,
    color: rgb(0.6, 0.6, 0.6),
    opacity: 0.75,
  });

  lastPage.drawText(userName, {
    x: 50,
    y: 100,
    font: ubuntuFont,
    color: rgb(0, 0, 0),
  });

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};
