import axios from "axios";

const insightApi = axios.create({
  baseURL: "https://new-insight.doconeapis.com",
});

const setAuthorizationHeader = (token) => {
  if (token) {
    insightApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete insightApi.defaults.headers.common["Authorization"];
  }
};

insightApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return fetchNewToken()
        .then((newToken) => {
          setAuthorizationHeader(newToken);
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axios(originalRequest);
        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
        });
    }
    return Promise.reject(error);
  }
);

async function fetchNewToken() {
  try {
    const formData = new URLSearchParams();
    formData.append("username", "jose");
    formData.append("password", "chatdoconetest2");

    const { data } = await insightApi.post("/auth/token", formData.toString(), {
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return data.access_token;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function getArticles({ query, page = 1, size = 5 }) {
  try {
    const { data } = await insightApi.post("/search_article/", {
      input_text: query,
      page,
      size,
      laboratory_procedure: false,
      therapeutic_procedure: false,
      diagnostic_procedure: false,
      number_of_years: 23,
      hlowest: 0,
      hhighest: 1331,
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function chatWithPrompt({ prompt, articles, history }) {
  try {
    const response = await insightApi.post("/chatbot/with_prompt", {
      user_input: prompt,
      articles: articles.map((article) => ({
        title: article.title,
        url: article.url,
        pmid: article.pmid,
        abstract: article.abstract,
        //  ...(Object.values(article.full_text).length ? { full_text: article.full_text } : null),
      })),
      history,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function checkChatResultStatus({ chatKey }) {
  try {
    const response = await insightApi.post("/chatbot/check_result", {
      key: chatKey,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export { getArticles, chatWithPrompt, checkChatResultStatus };
