import { ChangeEvent, useMemo, useState } from "react";

import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import FileExplorerDrawer from "src/components/Files/FileExplorerDrawer";
import { KebabMenuIcon, PDFIcon } from "src/components/Icons";
import { convertBytesToKBorMB, debounce, downloadFile } from "src/utils";
import { usePatientContext } from "src/contexts/patient.context";
import { downloadCaseFile } from "src/services/patient";
import { fileTypes } from "src/constants/common";

interface CaseFile {
  Filename: string;
  Size: number;
  fileType: string;
}

export default function MyFiles() {
  const { caseData } = usePatientContext();
  const [filteredFiles, setFilteredFiles] = useState<CaseFile[]>([]);

  const files = caseData?.files || [];

  const filesByType = useMemo(() => {
    return filteredFiles.reduce((acc, file) => {
      const fileType = file.fileType || "other";
      if (!acc[fileType]) {
        acc[fileType] = [];
      }
      acc[fileType].push(file);
      return acc;
    }, {});
  }, [filteredFiles]);

  if (files.length && !filteredFiles.length) {
    setFilteredFiles(files);
  }

  const handleSearchFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const fileName = value.trim().toLowerCase();
    if (!fileName) return setFilteredFiles(files);

    const matchedFiles = files.filter((file) => {
      return file.name.toLowerCase().includes(fileName);
    });

    setFilteredFiles(matchedFiles);
  };

  return (
    <>
      <Heading color="grayOne" fontSize="20px" fontWeight="700" marginBottom="16px">
        My files
      </Heading>
      <Box
        display="flex"
        alignItems="center"
        marginBottom="4"
        marginTop="4"
        width={["100%", "100%", "100%", "50%"]}
      >
        <Box position="relative" width="full">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.500" />}
              paddingLeft="3"
            />
            <Input
              boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
              borderRadius="100px"
              backgroundColor="white"
              color="gray.900"
              fontSize="sm"
              focusBorderColor="blue.500"
              width="full"
              paddingLeft="10"
              paddingTop="2.5"
              paddingBottom="2.5"
              placeholder={`Search files...`}
              onChange={debounce(handleSearchFiles, 300)}
            />
          </InputGroup>
        </Box>
      </Box>

      {files.length === 0 && (
        <Text color="gray.500" fontSize="sm">
          No files found
        </Text>
      )}

      <VStack spacing="16px">
        {fileTypes.map((fileType) => {
          const files = filesByType[fileType.value];
          if (!files) return null;

          return (
            <Box key={fileType.value} width="100%" marginBottom="30px">
              <Heading color="grayOne" fontSize="18px" fontWeight="700" marginBottom="16px">
                {fileType.label}
              </Heading>
              <SimpleGrid columns={[1, 2, 3, 4]} spacing="16px">
                {files.map((file, index) => (
                  <FileCard key={index} file={file} />
                ))}
              </SimpleGrid>
            </Box>
          );
        })}
      </VStack>
    </>
  );
}

function FileCard({ file }: any) {
  const { caseData } = usePatientContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDownloadFile = () => {
    const fileName = file.name;
    downloadCaseFile({ fileName, caseId: caseData.id }).then((data) => {
      downloadFile({ fileName, data });
    });
  };

  const handlePreviewFile = async () => {
    try {
      const fileName = file.name;
      const base64File = await downloadCaseFile({ fileName, caseId: caseData.id });
      if (!base64File) {
        throw new Error(`Could not preview file ${fileName}`);
      }
      const fileBlob = new Blob([base64File], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileBlob);

      window.open(fileURL, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Box
        height="256px"
        boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
        borderRadius="8px"
        background="white"
        flex="1"
      >
        <VStack padding="20px" paddingTop="40px">
          <PDFIcon />
          <Text as="span" maxWidth="100%" color="brand.blue" fontWeight="600" isTruncated>
            {file.name}
          </Text>
        </VStack>
        <HStack
          align="left"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          borderTop="2px solid #F7F9FD"
          padding="5px 20px"
        >
          <Text color="#373B4A" fontWeight="400">
            File size:{" "}
            <Text as="span" color="brand.blue" fontWeight="700">
              {convertBytesToKBorMB(file.size)}
            </Text>
          </Text>
          <Menu>
            <MenuButton as={IconButton} variant="ghost" icon={<KebabMenuIcon />} />
            <MenuList>
              <MenuItem onClick={onOpen}>Explore</MenuItem>
              <MenuItem onClick={() => handlePreviewFile()}>Preview</MenuItem>
              <MenuItem onClick={() => handleDownloadFile()}>Download</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Box>
      {isOpen ? (
        <FileExplorerDrawer key={file.key} fileName={file.name} isOpen={isOpen} onClose={onClose} />
      ) : null}
    </>
  );
}
