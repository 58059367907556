import { Heading, Image, VStack } from "@chakra-ui/react";
import React from "react";

export default function ConsentForm() {
  return (
    <VStack align="left" gap="16px" maxWidth="600px" margin="100px auto" className="main-container">
      <Image src={"../TMA_Logo.png"} />
      <Heading as="h1">Consent Forms</Heading>
      <div className="text-large">Last edited July 27, 2022</div>
      <div className="horizontal-rule"></div>
      <VStack align="left" gap="12px" className="article w-richtext">
        <Heading as="h2" size="lg">
          Consent to Data Use
        </Heading>
        <p>Terminology:</p>
        <p>“Agreement” means this TMA Precision Health Consent to Data Use agreement.</p>
        <p>
          “Anonymous Database” means the secure computer database of De-Identified Information controlled by TMA which
          is separate from the Collected Database.
        </p>
        <p>
          “Collected Database” means the secure computer database(s) of Collected Information controlled by TMA or your
          Provider(s).
        </p>
        <p>
          “Collected Information” means the information you, your Providers, or a third party on you or your Providers’
          behalf send to TMA, including information that contains your name, address, age, gender, the primary language
          you speak, health insurance information, and Health Data.{" "}
        </p>
        <p>“Data” means Collected Information and De-Identified Information.</p>
        <p>“De-Identified Information” means Collected Information with Personal Information removed. </p>
        <p>
          “Health Data” means your medical information provided by you or your Provider, including but not limited to
          family medical history, test results, diagnoses, medications, immunizations, allergies, etc., and information
          about your previous, current, or scheduled treatment or procedures.
        </p>
        <p>
          “Personal Information” means information by which you may be personally identified, such as name, title,
          address, e-mail address, telephone number, social security number, date of birth, or financial information.
        </p>
        <p>
          “Provider” means any medical professional or other person or entity that is authorized to collect, retain,
          store, or provide medical information, or any individual authorized to act on behalf of any of the foregoing’s
          individuals or entities. &nbsp;
        </p>
        <p>“TMA” means TMA Precision Healthcare.</p>
        <p> </p>
        <p>Background:</p>
        <p>
          <em>Who We Are</em>: TMA is a company that helps you and your care team better understand your unique health
          needs. TMA does this by collecting and storing Collected Information from you and your Providers, removing
          Personal Information from Collected Information to create De-Identified Information, and organizing and
          processing De-Identified Information.{" "}
        </p>
        <p> </p>
        <p>
          <em>Laws Protecting Your Data</em>: Data is protected according to applicable local, state, federal, and
          international laws and regulations, including the Health Insurance Portability and Accountability Act (HIPAA),
          the General Data Protection Regulation (GDPR) and California Consumer Protection Act (CCPA).{" "}
        </p>
        <p> </p>
        <p>
          <em>Benefits of Allowing Us Access to Your Data</em>: Allowing TMA to access and use your Data allows you
          and/or your care team to:
        </p>
        <ul>
          <li>Use our research platform;</li>
          <li>Be eligible for free products and services available through TMA’s partner network; and</li>
          <li>Get access to medical expert opinion pertinent to your specific health needs.</li>
        </ul>
        <p> </p>
        <p>
          <em>How TMA Uses Your Data</em>:{" "}
        </p>
        <p>Your Data is used as follows:</p>
        <ul>
          <li>
            Your Collected Information is used to contact you about opportunities to participate in research, including
            clinical trials;
          </li>
          <li>Your De-Identified Information is used for commercial and academic research; and</li>
          <li>
            Your Data is used to provide you opportunities to continue to learn from your own Collected Information
            through your TMA account, and any insights to treatment options or care paths pertinent to your personal
            health needs.
          </li>
        </ul>
        <p> </p>
        <p>
          <em>How Information is De-identified</em>: If you agree to TMA’s access and use of your Collected Information,
          TMA will remove Personal Information from your Collected Information, and store all or part of your
          De-Identified Information in accordance with this consent. Prior to any use of De-Identified Information, we
          will remove Personal Information.{" "}
        </p>
        <p> </p>
        <p>
          <em>How Data is Stored</em>: TMA is committed to protecting your privacy. TMA stores Collected Information in
          a Collected Database. Only pre-approved authorized people at TMA will have access to the Collected Database.
          The Collected Information in a Collected Database will be processed to remove Personal Information, making
          De-Identified Information. De-Identified Information will be stored and analyzed on the Anonymous Database.
          TMA may create a code number that links De-Identified Information in the Anonymous Database to your Collected
          Information in the Collected Database to permit you to remove your Data.{" "}
        </p>
        <p> </p>
        <p>
          <em>Genetic Information</em>: If there is genetic information in your health records, TMA will use, store, and
          disclose that information only in accordance with federal laws. If you would like more information about how
          TMA handles genetic information, you can send an email to info@tmaprecisionhealth.com.
        </p>
        <p> </p>
        <p>
          <em>Withdrawal of Consent</em>: You can change your mind at any time about anything to which you are
          committing when you sign up. If you would no longer like to store your Data with us, you can let us know and
          we will remove all, or whatever portion you would like removed, of your Data from our database. If you change
          your mind, you will have two choices: (1) you can stop future Collected Information and De-Identified
          Information from going in the Collected Database and Anonymous Database, respectively, but continue to let TMA
          use what is already in the Collected Database and Anonymous Database, or (2) you could ask TMA to remove your
          Data en toto from both the Collected Database and Anonymous Database. &nbsp;
        </p>
        <p> </p>
        <p>Contact Information:</p>
        <p> </p>
        <p>
          If you want to stop participating in the TMA research bank, you can contact TMA by sending an email to
          info@tmaprecisionhealth.com. If you want total your Providers to stop sending your health information to TMA,
          please contact them directly.{" "}
        </p>
        <p> </p>
        <p>If you have any questions about this consent, please email info@tmaprecisionhealth.com.</p>
        <p>‍</p>
        <h2>Consent to Access Your Health Information/Participate in Research</h2>
        <p>
          <em>
            Please read this document carefully. If you consent to TMA’s use of your Data and the terms and condition
            set forth herein, please sign this form by clicking "Agree".
          </em>
        </p>
        <p> </p>
        <p>
          <em>I hereby authorize and grant TMA the right to:</em>‍
        </p>
        <ul>
          <li>Access, use, collect, and store my Data; &nbsp;</li>
          <li>Remove Personal Information from my Collected Information to create De-Identified Information;</li>
          <li>
            Apply on my behalf your electronic signature, initials, and identifying information on any additional
            authorization forms required by my Providers, solely for the purpose of requesting the release of Heath
            Data;{" "}
          </li>
          <li>
            Submit or organize on my behalf the collection of samples including tissue, blood, device data, etc. to
            medical products and services for the purposes of additional diagnostics and to collect the output of these
            services on your behalf, deidentify it, and add it to the Collected Database and/or Anonymous Database;
          </li>
          <li>
            Evaluate my ability to participate in future opportunities such as research studies and clinical trials;
          </li>
          <li>Use my De-Identified Information for research and commercial purposes;</li>
          <li>Provide TMA’s research partners access to my Collected Information; and</li>
          <li>Submit my De-Identified Information to research databases for use in scientific and medical research.</li>
        </ul>
        <p> </p>
        <p>
          <em>I hereby acknowledge and agree:</em>
        </p>
        <ul>
          <li>
            TMA may receive my Data from my Providers and/or third-party partners on behalf of my Providers, and that
            these partners may require me to agree to terms of service that will pertain to the services that they
            provide to me and that may give them rights to retain and use my De-Identified Information.
          </li>
          <li>
            I am not required to agree to any third-party terms to provide Collected Information, but if I do not agree,
            I may not be able to utilize the services provided by those partners to provide TMA with my Collected
            Information.{" "}
          </li>
          <li>
            When TMA provides my De-Identified Information to research partners or research databases, my De-Identified
            Information may no longer be protected by HIPAA or other federal, state, or local laws, and could possibly
            be used or disclosed in ways other than those listed in this Agreement.{" "}
          </li>
          <li>
            The research or studies conducted by TMA using my De-Identified Information may not help me individually but
            may help others.{" "}
          </li>
          <li>The Data collected by TMA may not be my complete medical record;</li>
          <li>
            If research done with my De-Identified Information uncovers information about my current or future health,
            such as whether I have a marker for a particular disease (called "Incidental Findings"), my Providers and I
            will likely not be notified of the Incidental Findings, and those Incidental Findings will not be put into
            my medical record.{" "}
          </li>
          <li>
            Any of my results, data, opinion, and other outputs from collections of samples submitted or organized by
            TMA may not result in a complete diagnosis or comprehensive assessment of my current medical condition.
          </li>
          <li>
            Granting TMA the rights under this Agreement will not violate any agreement with any third party to which I
            am a party.{" "}
          </li>
          <li>
            I can change my mind at any time and discontinue collection of my Data, as set forth in this Agreement, but
            if TMA has already provided my De-Identified Information to other researchers prior to me changing my mind,
            TMA will not be able to give it back. &nbsp;
          </li>
          <li>TMA controls the Collected Database and Anonymous Database.</li>
          <li>
            TMA is currently owned by private shareholders and many of TMA's employees also have an equity interest in
            TMA, and TMA and its employees could benefit from the use of my Data, and I do not have any rights to share
            in any profits that result from use of my Data.
          </li>
          <li>
            Although unlikely, if there is an accidental release of my Data, it may negatively impact my ability to
            obtain certain types of insurance coverage, my family relationships, or other legal rights.
          </li>
          <li>My Data is also subject to TMA’s website Privacy Policy and Terms of Use.</li>
          <li>I am entering into this Agreement voluntarily and will not be compensated for my Data. </li>
        </ul>
        <p> </p>
        <p> </p>
        <p>
          <em>By selecting "Agree", I hereby agree to the terms of this Agreement and represent and warrant that:</em>
        </p>
        <p>
          <em> 1) &nbsp; &nbsp;I have read and understand all of the terms of this Agreement;</em>
        </p>
        <p>
          <em>2) &nbsp; &nbsp;I am at least 18 years of age.</em>
        </p>
      </VStack>
    </VStack>
  );
}
