import Medications from "src/components/DashboardCards/Medications";
import { Heading } from "@chakra-ui/react";
import { usePatientContext } from "src/contexts/patient.context";

export default function MyMedicationsPage() {
  const { caseData } = usePatientContext();

  return (
    <>
      <Heading color="grayOne" fontSize="20px" fontWeight="700" marginBottom="16px">
        My Medications
      </Heading>
      <Medications data={caseData?.medications || []} />
    </>
  );
}
