import ReactJoyride from "react-joyride";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "./contexts/auth.context";
import { useTourContext } from "./contexts/tour.context";
import Router from "./router";
import { getJoyRideCallback, getTourSteps } from "./utils/app-tour.utils";

function App() {
  const { setState, state } = useTourContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { run, stepIndex } = state;

  return (
    <>
      <Router />
      <ReactJoyride
        callback={getJoyRideCallback({ location, navigate, setState, stepIndex, user })}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={getTourSteps(user)}
      />
    </>
  );
}
export default App;
