import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import CtmConsentPDFSigner from "./CtmConsentPDFSigner";

function CtmConsentFormModal({ ctmData, onSignComplete }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {!ctmData?.consentAgreed && (
        <Alert status="warning">
          <AlertIcon />
          You have not signed the consent form yet. Please sign it before proceeding.
          <Button marginLeft="4" colorScheme="yellow" onClick={onOpen}>
            Sign Now
          </Button>
        </Alert>
      )}
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sign Consent Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CtmConsentPDFSigner
              ctmData={ctmData}
              onSignComplete={() => {
                onSignComplete();
                onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CtmConsentFormModal;
