import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_RARECARE_API || "https://api.rarecare.com";

export async function getCareTeamMemberPatients(userId: string) {
  const endpoint = API_BASE_URL + `/care-team-members/by-user-id/${userId}/patients`;
  return axios.get(endpoint).then((res) => res.data);
}

export async function getCareTeamMemberByUserId(userID: string) {
  const endpoint = API_BASE_URL + `/care-team-members/by-user-id/${userID}`;
  return axios.get(endpoint).then((res) => res.data);
}

export async function getCareTeamInvites(userID: string) {
  const endpoint = API_BASE_URL + `/care-team-members/by-user-id/${userID}/invites`;
  return axios.get(endpoint).then((res) => res.data);
}

export async function registerCareTeamMember(data: any) {
  const endpoint = API_BASE_URL + "/care-team-members/register";
  return axios.post(endpoint, data).then((res) => res.data);
}

export async function acceptPatientInvite(inviteId: string) {
  const endpoint = API_BASE_URL + "/care-team-members/accept-invite";
  return axios.post(endpoint, { inviteId }).then((res) => res.data);
}

export async function declinePatientInvite(inviteId: string) {
  const endpoint = API_BASE_URL + "/care-team-members/decline-invite";
  return axios.post(endpoint, { inviteId }).then((res) => res.data);
}

export async function searchCareTeamMembers(query: string) {
  try {
    const endpoint = API_BASE_URL + `/care-team-members/search?query=${encodeURIComponent(query)}`;
    const { data }: any = await axios.get(endpoint);
    return data || [];
  } catch (error: any) {
    console.error(error);
    return { error, message: error.message, data: [] };
  }
}

export async function sendCareTeamMemberInviteEmail({ email, patientId }: any) {
  try {
    const endpoint = API_BASE_URL + `/care-team-members/invite-by-email`;
    const { data }: any = await axios.post(endpoint, { email, patientId });
    return data;
  } catch (error: any) {
    console.error(error);
    return { error, message: error.message, data: null };
  }
}

export function inviteCareTeamMember({ patientId, careTeamMemberId }: any) {
  return axios.post(API_BASE_URL + `/care-team-members/invite`, {
    patientId,
    careTeamMemberId,
  });
}

export async function uploadFileToCTM(data: any) {
  return axios.post(API_BASE_URL + "/care-team-members/upload-file", {
    careTeamMemberId: data.careTeamMemberId,
    fileName: data.fileName,
    fileBase64: data.fileBase64,
    fileType: data.fileType,
  });
}
