import { useLayoutEffect, useState } from "react";

export default function useDimensions() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isDesktop = width > 768;
  const isMobile = width <= 768;

  return { isDesktop, isMobile };
}
