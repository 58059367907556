import axios from "axios";

const api = axios.create({
  baseURL: "https://dev.doconeapis.com",
});

const setAuthorizationHeader = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return fetchNewToken()
        .then((newToken) => {
          setAuthorizationHeader(newToken);
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axios(originalRequest);
        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
        });
    }
    return Promise.reject(error);
  }
);

async function fetchNewToken() {
  try {
    const formData = new URLSearchParams();
    formData.append("username", "jose");
    formData.append("password", "chatdoconetest2");

    const { data } = await api.post("/auth/token", formData.toString(), {
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return data.access_token;
  } catch (error) {
    throw error;
  }
}

async function getConditions({ query, page = 1, perPage = 20 }) {
  try {
    const { data } = await api.get("/conditions/", {
      params: {
        page,
        per_page: perPage,
        search: query,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

async function getPhysicians({ address, condition, page = 1, perPage = 20 }) {
  try {
    const { data } = await api.get("/physicians/", {
      params: {
        address,
        condition,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export { getConditions, getPhysicians };
