import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Stack,
  Button,
  Heading,
  Text,
  Link,
  Image,
  Spinner,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import Select from "src/components/Select";
import { useAuth } from "src/contexts/auth.context";
import { registerCareTeamMember } from "src/services/care-team";

function validatePassword(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d])[\w\W]{12,}$/;
  return passwordRegex.test(password);
}

export default function CareTeamMemberSignup() {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    specialty: "",
    medicalAffiliation: "",
    phoneNumber: "",
    password: "",
    passwordConfirmation: "",
  });

  const [params] = useSearchParams();

  const { isLoading, signIn, isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  const handleChange = (name: string, value: string | boolean) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignup = async (e: any) => {
    e.preventDefault();
    const data = {
      name: state.firstName,
      familyName: state.lastName,
      email: state.email,
      title: state.title,
      specialty: state.specialty,
      phoneNumber: state.phoneNumber,
      medicalAffiliation: state.medicalAffiliation,
      password: state.password,
    };

    if (params.get("patientId")) {
      data["patientId"] = params.get("patientId");
    }

    if (state.password !== state.passwordConfirmation) return;

    try {
      setLoading(true);
      const response = await registerCareTeamMember(data);
      if (response.error) {
        alert(response.error || "Error signing up. Please try again.");
        return;
      }
      const { success, message } = await signIn(state.email, state.password);
      if (!success) {
        alert(message);
      }
    } catch (error: any) {
      console.error(error);
      const errorMessage = error?.response?.data?.error || error.message;
      alert(errorMessage || "Error signing up. Please try again.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex minH={"100vh"} direction="column" align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing="6">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Image src={"../TMA_Logo.png"} />
        </Stack>
      </Stack>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            CTM Sign Up
          </Heading>
        </Stack>
        <Box as="form" rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8} onSubmit={handleSignup}>
          <Stack spacing={4}>
            <HStack>
              <FormControl id="firstName" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  value={state.firstName}
                  required
                  onChange={(e) => handleChange("firstName", e.target.value)}
                />
              </FormControl>
              <FormControl id="lastName">
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  value={state.lastName}
                  required
                  onChange={(e) => handleChange("lastName", e.target.value)}
                />
              </FormControl>
            </HStack>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={state.email}
                required
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </FormControl>

            <FormControl id="phoneNumber">
              <FormLabel>Phone number</FormLabel>
              <Input
                type="text"
                value={state.phoneNumber}
                required
                onChange={(e) => handleChange("phoneNumber", e.target.value)}
              />
            </FormControl>

            <FormControl id="previous-genetic-screening" isRequired>
              <FormLabel>Role / title</FormLabel>
              <Select
                options={[
                  { label: "Family member", value: "ctm_family" },
                  { label: "Medical Professional", value: "ctm_medical" },
                  { label: "Advocate", value: "ctm_sample_collection" },
                ]}
                required
                onChange={({ value }: any) => handleChange("title", value)}
              />
            </FormControl>

            {state.title === "Medical Professional" && (
              <>
                <FormControl id="specialty">
                  <FormLabel>Specialty</FormLabel>
                  <Input
                    type="text"
                    value={state.specialty}
                    required
                    onChange={(e) => handleChange("specialty", e.target.value)}
                  />
                  <FormHelperText>
                    Your specialty or focus (Geneticist, Neurology, etc..)
                  </FormHelperText>
                </FormControl>

                <FormControl id="medicalAffiliation">
                  <FormLabel>Medical affilitation</FormLabel>
                  <Input
                    type="text"
                    value={state.medicalAffiliation}
                    required
                    onChange={(e) => handleChange("medicalAffiliation", e.target.value)}
                  />
                  <FormHelperText>
                    Your medical affilitation (ie: Mt Sinai, NYU, etc..)
                  </FormHelperText>
                </FormControl>
              </>
            )}

            <FormControl id="password" isRequired isInvalid={!validatePassword(state.password)}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                autoComplete="off"
                value={state.password}
                required
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <FormErrorMessage>
                Password must be at least 12 characters, has uppercase, lowercase, number and a
                special character (^$*@#%&=+-)
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="passwordConfirmation"
              isRequired
              isInvalid={state.password !== state.passwordConfirmation}
            >
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                autoComplete="off"
                value={state.passwordConfirmation}
                required
                onChange={(e) => handleChange("passwordConfirmation", e.target.value)}
              />
              <FormErrorMessage>Passwords do not match</FormErrorMessage>
            </FormControl>

            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                type="submit"
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                isDisabled={
                  state.password !== state.passwordConfirmation ||
                  !validatePassword(state.password) ||
                  loading ||
                  isLoading
                }
              >
                {loading ? "Signing up" : "Sign up"}
                {loading && <Spinner />}
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={"center"}>
                Already a user?{" "}
                <Link color={"blue.400"} href="/login">
                  Login
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
