import { Button, Heading, HStack, Text, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import CareTeamLayout from "src/components/CareTeamLayout";
import Loader from "src/components/Loader";
import AppTable from "src/components/Table";
import { useAuth } from "src/contexts/auth.context";
import {
  acceptPatientInvite,
  declinePatientInvite,
  getCareTeamInvites,
} from "src/services/care-team";

const CareTeamInvites = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const {
    data: invites,
    status,
    isError,
    refetch,
  } = useQuery(["care-team-invites", user.username], () => {
    return getCareTeamInvites(user.username);
  });

  const isLoading = status === "loading";

  const handleAcceptInvite = (inviteId: string) => {
    setLoading(true);
    acceptPatientInvite(inviteId)
      .then((res) => {
        refetch();
        toast({
          title: res.message || "Invite accepted!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: err.error || "Invite accept failed!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeclineInvite = (inviteId: string) => {
    setLoading(true);
    declinePatientInvite(inviteId)
      .then((res) => {
        refetch();
        toast({
          title: res.message || "Invite declined!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: err.error || "Invite decline failed!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Text>Something went wrong. Try reloading the page!</Text>;
  }

  const columns = [
    {
      Header: "Patient First Name",
      Cell: ({ row }: any) => {
        const { patientName } = row.original;
        return <Text>{`${patientName}`}</Text>;
      },
    },
    {
      Header: "Patient Last Name",
      Cell: ({ row }: any) => {
        const { patientLastName } = row.original;
        return <Text>{` ${patientLastName}`}</Text>;
      },
    },
    {
      Header: "Patient Email",
      Cell: ({ row }: any) => {
        const { patientEmail } = row.original;
        return <Text>{patientEmail}</Text>;
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }: any) => {
        const { id } = row.original;
        return (
          <HStack>
            <Button
              variant="outline"
              colorScheme="green"
              size="sm"
              disabled={loading}
              onClick={() => handleAcceptInvite(id)}
            >
              Accept
            </Button>
            <Button
              variant="outline"
              colorScheme="red"
              size="sm"
              disabled={loading}
              onClick={() => handleDeclineInvite(id)}
            >
              Decline
            </Button>
          </HStack>
        );
      },
    },
  ];

  return (
    <CareTeamLayout>
      <Heading as="h1" fontSize="20px" color="brand.gray1" mb="4">
        Invites
      </Heading>
      {loading && <Loader />}
      <AppTable columns={columns} data={invites || []} withSearch />
    </CareTeamLayout>
  );
};

export default CareTeamInvites;
