import { Box, IconButton } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { FiBell } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth.context";

export default function NotificationBadge({ count }: { count: number }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <IconButton
      css={css`
        position: relative !important;
      `}
      py={"2"}
      colorScheme={"gray"}
      aria-label={"Notifications"}
      size={"lg"}
      onClick={() => {
        navigate(`/care-team-member/${user.username}/invites`);
      }}
      icon={
        <>
          <IconButton size="lg" variant="ghost" aria-label="notification" icon={<FiBell />} />
          {count > 0 && (
            <Box
              as={"span"}
              color={"white"}
              position={"absolute"}
              top={"0px"}
              right={"0px"}
              fontSize={"0.8rem"}
              bgColor={"red"}
              borderRadius="full"
              zIndex={9999}
              p={"2px"}
              minW={"20px"}
              minH={"20px"}
            >
              {count}
            </Box>
          )}
        </>
      }
    />
  );
}
