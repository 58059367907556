import Select from "react-windowed-select";

const customReactSelectStyles = {
  control: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    height: "30px",
    width: "100%",
    borderRadius: "4px",
    borderColor: isFocused ? "#3182ce" : "inherit",
    background: isDisabled ? "white" : "",
    color: isDisabled ? "gray" : "",
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: 0,
    borderRadius: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#718096",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export default function VirtualizedSelect(props) {
  return <Select {...props} styles={customReactSelectStyles} />;
}
