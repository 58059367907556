import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ProvideAuth } from "./contexts/auth.context";
import { TourProvider } from "./contexts/tour.context";
import App from "./App";
import "./global.css";

const queryClient = new QueryClient();

const chakraTheme = extendTheme({
  colors: {
    brand: {
      blue: "#496CA1",
      darkblue: "#234670",
      lightblue: "#59AFD6",
      paleblue: "#F7F9FD",
      lightgreen: "#B0D5D3",
      yellow: "#FAF089",
      lightyellow: "#FFC229",
      paleyellow: "#FFFAEE",
      gray1: "#373B4A",
      gray2: "#B8BCD1",
      gray3: "#E1E3EF",
      gray4: "#F4F6FD",
      darkgradient: "linear-gradient(180deg, #234670 0%, #496CA1 100%)",
      lightgradient: "linear-gradient(204.2deg, #496CA1 13.84%, #B0D5D3 116.34%)",
    },
  },
});

const emotionCache = createCache({
  key: "emotion-cache",
  prepend: true,
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <CacheProvider value={emotionCache}>
        <ChakraProvider theme={chakraTheme}>
          <ProvideAuth>
            <TourProvider>
              <App />
            </TourProvider>
          </ProvideAuth>
        </ChakraProvider>
      </CacheProvider>
    </BrowserRouter>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
