import ReactSelect, { Props } from "react-select";

const customReactSelectStyles = {
  control: (styles: any, { isDisabled, isFocused }: any) => ({
    ...styles,
    height: "30px",
    width: "100%",
    borderRadius: "4px",
    borderColor: isFocused ? "#3182ce" : "inherit",
    background: isDisabled ? "white" : "",
    color: isDisabled ? "gray" : "",
  }),
  menu: (styles: any) => ({
    ...styles,
    marginTop: 0,
    borderRadius: "none",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#718096",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

export default function Select(props: Props) {
  return <ReactSelect {...props} styles={customReactSelectStyles} />;
}
