import React from "react";
import { Box } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import Loader from "src/components/Loader";
import { useAuth } from "src/contexts/auth.context";

export default function Home() {
  const { isAuthenticated, isLoading, user } = useAuth();

  if (isLoading) {
    return (
      <Box display="grid" placeItems="center" width="100%" height="100%">
        <Loader />
      </Box>
    );
  }

  if (isAuthenticated && user.role === "patient") {
    return <Navigate to={`/patient/${user.username}/dashboard`} replace />;
  }

  if (isAuthenticated && user.role === "care-team-member") {
    return <Navigate to={`/care-team-member/${user.username}/dashboard`} replace />;
  }

  return <Navigate to="/login" replace />;
}
