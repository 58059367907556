import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import SelectAsync from "react-select/async";
import { getConditions, getPhysicians } from "src/services/doc-one/dev-docone";

import Layout from "src/components/Layout";
import { MdLocalHospital, MdLocationOn, MdPhone } from "react-icons/md";
import Loader from "src/components/Loader";

const PhysicianSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [results, setResults] = useState([]);

  const loadConditions = async (inputValue, callback) => {
    try {
      const data = await getConditions({ query: inputValue });
      const conditions = data.items.map((item) => ({
        label: item.condition,
        value: item.condition_id,
      }));
      callback(conditions);
    } catch (error) {
      console.error("Error fetching conditions:", error);
      alert("Error fetching conditions");
    }
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      setMessage("");
      const data = await getPhysicians({
        address,
        condition: selectedCondition.label,
      });
      setResults(data.items);
      if (data.items.length === 0) {
        setMessage("No physicians found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Box p={4} maxW="3xl">
        <Heading mb="4">Physicians search</Heading>
        <Divider mb="4" />
        <Stack spacing={4} background="white" padding="5">
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input
              value={address}
              placeholder="type address here..."
              onChange={(e) => setAddress(e.target.value)}
            />
            <FormHelperText>
              Enter a city, state, or zip code to find physicians near you.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Condition</FormLabel>
            <SelectAsync
              cacheOptions
              defaultOptions
              loadOptions={loadConditions}
              value={selectedCondition}
              onChange={(value) => setSelectedCondition(value)}
              placeholder="Search for a medical condition..."
            />
            <FormHelperText>
              Enter a medical condition to find physicians who treat it.
            </FormHelperText>
          </FormControl>
          <Button colorScheme="blue" onClick={handleSearch}>
            Search
          </Button>
        </Stack>
        <VStack mt={4} align="stretch" spacing={4} background="white" padding="5">
          {isLoading && <Loader loading />}
          {message && <Text>{message}</Text>}
          {results.map((physician) => (
            <Box
              key={physician.npi}
              borderWidth="1px"
              p={4}
              rounded="md"
              shadow="md"
              borderColor="gray.300"
            >
              <Text fontWeight="bold">
                Dr. {physician.first_name} {physician.last_name}
              </Text>
              <Text mb="4">{physician.primary_specialty?.specialty || ""}</Text>

              <HStack>
                <MdLocationOn />
                <Text>
                  {physician.addresses?.[0]?.address}, {physician.addresses?.[0]?.city},{" "}
                  {physician.addresses?.[0]?.state} {physician.addresses?.[0]?.zipcode}
                </Text>
              </HStack>

              <HStack>
                <MdPhone />
                <Text>{physician.addresses?.[0]?.phone_number}</Text>
              </HStack>
              {physician.hospital_affiliations?.[0]?.lbn && (
                <HStack>
                  <MdLocalHospital></MdLocalHospital>
                  <Text>{physician.hospital_affiliations?.[0]?.lbn}</Text>
                </HStack>
              )}
            </Box>
          ))}
        </VStack>
      </Box>
    </Layout>
  );
};

export default PhysicianSearch;
