import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Heading,
  HStack,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { MdOutlineVerified } from "react-icons/md";
import { getSteps, stepsReducer } from "src/utils/account-steps.utils";

export default function AccountProgressStepper({ patient }: any) {
  const steps = getSteps(patient.profileCompletion);

  const index = steps.reduce(stepsReducer, 0);
  const { activeStep } = useSteps({ index, count: steps.length });
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false });

  return (
    <Box
      flex="2"
      background="white"
      maxWidth="100%"
      padding="41px"
      paddingTop="20px"
      boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
      borderRadius="8px"
    >
      <Heading color="grayOne" fontSize="20px" fontWeight="700" marginBottom="16px">
        Account Progress
      </Heading>
      <Stepper
        index={activeStep}
        orientation={isMobile ? "vertical" : "horizontal"}
        style={{ alignItems: "start" }}
      >
        {steps.map((step, index) => (
          <Step key={index} style={{ alignItems: "start" }}>
            <Box display="flex" gap="2">
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                {step.tasks.map((task, index) => (
                  <HStack key={task.title + index}>
                    <StepDescription>
                      <Text as="span" color={task.completed ? "brand.blue" : ""}>
                        {task.title}
                      </Text>
                    </StepDescription>
                    {task.completed ? <MdOutlineVerified color="green" /> : null}
                  </HStack>
                ))}
              </Box>
            </Box>

            <StepSeparator style={{ marginTop: "16px" }} />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
