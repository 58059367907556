import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { updatePatient } from "src/services/patient";
import Loader from "./Loader";

export default function DnaSampleCollectedModal({
  isOpen,
  onClose,
  patient,
  onSubmissionCompleted,
}) {
  const toast = useToast();

  const patientMutation = useMutation(updatePatient, {
    onSuccess: (response) => {
      onSubmissionCompleted?.();
      onClose();
      toast({
        position: "top-right",
        status: "success",
        title: "Kit serial number added successfully.",
      });
    },
    onError: (err) => {
      console.error(err);
      toast({
        position: "top-right",
        status: "error",
        title: "Failed to add kit serial number. Please try again.",
      });
    },
  });

  const loading = patientMutation.isLoading;

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const kitSerialNumber = formData.get("kitSerialNumber");
    patientMutation.mutate({
      patientId: patient.id,
      data: { kitSerialNumber },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Collect DNA Sample</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" id="dna-sample-collected-form" onSubmit={handleSubmit}>
          {loading && <Loader />}
          <FormControl>
            <FormLabel required>Kit Serial Number</FormLabel>
            <Input
              required
              type="text"
              name="kitSerialNumber"
              placeholder="Type kit serial number here"
              isDisabled={loading}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" isDisabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            type="submit"
            form="dna-sample-collected-form"
            mr={3}
            isDisabled={loading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
