import { Button, FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";

const SigningForm = ({ onSign }) => {
  const handleSign = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const userName = formData.get("userName");
    onSign(userName);
  };

  return (
    <VStack as="form" maxW="xl" marginX="auto" onSubmit={handleSign}>
      <FormControl isRequired>
        <FormLabel>Enter your full name to sign the document</FormLabel>
        <Input
          required
          type="text"
          autoComplete="off"
          borderColor="blue.500"
          name="userName"
          placeholder="Type your full name here to sign"
        />
      </FormControl>
      <Button width="100%" colorScheme="blue" type="submit">
        Sign Consent Form
      </Button>
    </VStack>
  );
};

export default SigningForm;
