import { Box, Spinner } from "@chakra-ui/react";

export default function Loader() {
  return (
    <Box width="100%" display="grid" placeItems="center">
      <div>
        <h5>Loading...</h5>
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </div>
    </Box>
  );
}
