import { Box, Heading, Image, VStack } from "@chakra-ui/react";
import React from "react";

export default function TermsAndConditions() {
  return (
    <Box maxWidth="600px" margin="100px auto">
      <VStack align="left">
        <Image src={"../TMA_Logo.png"} />
        <Heading>Terms &amp;&nbsp;Conditions</Heading>
        <div className="text-large">Last edited July 27, 2022</div>
        <div className="horizontal-rule"></div>
        <VStack align="left" gap="12px">
          <p>
            These Terms of Use are entered into by and between You and TMA Precision Health (“TMA” or “we”, “us” or
            “our”). The following terms together with the Privacy Policy and TMA Precisions Health Consent to Data Use
            (“Consent Agreement”), and any other documents they expressly incorporate by reference, and any other terms
            and conditions which may also apply to specific portions, services or features of the Website (collectively,
            the “Terms of Use”), govern your access to and use of the TMA website or any mobile applications provided by
            TMA.{" "}
          </p>
          <p>
            These Terms of Use apply to TMA’s website located at www.tmaprecisionhealth.com, and all other mobile sites,
            services, applications, platforms and tools where these Terms of Use appear or are linked (collectively, the
            “Website”). The Website may provide users with access to an online or mobile collection of information,
            databases, materials, content, and certain online and application services (collectively, the “Services”).
            The Website also contains text, pictures, graphics, logos, button items, images, works of authorship, data,
            and other content (collectively, “Content”), and may provide access to certain software used in connection
            with navigation and utilizing the functionality offered through this Website (“Software”).{" "}
          </p>
          <p>
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE YOU USE THE SITE. BY ACCESSING OR USING THE SITE, YOU ACCEPT
            AND AGREE TO BE BOUND BY THESE TERMS OF USE. IF YOUDO NOT AGREE TO THESE TERMS, YOU MUST NOT ACCESS OR USE
            THIS SITE OR THE SERVICES.{" "}
          </p>
          <p> </p>
          <p>ELIGIBILITY REQUIREMENTS</p>
          <p>
            By using this Website and the Services, you represent and warrant that: (1) you have the legal power and
            authority to agree to, and to be bound by, these Terms of Use; and (2) if you provide or have provided
            information to us in connection with your use of the Website or Services, such information is true and
            accurate. Neither this Website nor the Services are intended to be used by anyone under the age of 13. By
            using or accessing the Services, you represent that you are 18 years of age or older. If you are using or
            accessing the Website or Services as a legal representative, you warrant and represent that you have the
            legal authority to do so.
          </p>
          <p> </p>
          <p>ACCESSING THE SITE &amp; ACCOUNT SECURITY</p>
          <p>
            We reserve the right to withdraw or amend this Website, and any Service we provide on the Website, in our
            sole discretion without notice. We will not be liable if for any reason all or any part of the Website is
            unavailable at any time, for any period, or for any reason. From time to time, we may restrict access to
            some parts of the Website, or the entire Website, to users. Therefore, you are advised not to rely on the
            Website’s availability.
          </p>
          <p> </p>
          <p>
            To access the Website or some of the Services it offers, you may be asked to provide certain information, as
            detailed in our Privacy Policy and Consent to Data Use agreements. It is a condition of your use of the
            Website that all the information you provide on the Website is correct, current, and complete. You agree
            that all information you provide through this Website or otherwise, including but not limited to these of
            any interactive features on the Website, is governed by our Privacy Policy and Consent Agreement, and you
            consent to all actions we take with respect to your information consistent with those policies.
          </p>
          <p> </p>
          <p>INTELLECTUAL PROPERTY RIGHTS AND RESTRICTIONS</p>
          <p>
            This Website and its entire Contents, features, and functionality (including but not limited to all
            information, Software, text, displays, images, video and audio, and the design, selection, and arrangement
            thereof), and the Services are owned by TMA, its licensors, its services providers, or other providers of
            such material and are protected by the United States and international copyright, trademark, patent, trade
            secret and other intellectual property or propriety right laws.{" "}
          </p>
          <p> </p>
          <p>
            You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
            republish, download, store or transmit any Contention the Website or Services, except as follows:
          </p>
          <p> </p>
          <ul>
            <li>
              Your computer or device may temporarily store copies of such materials in RAM incidental to your accessing
              and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web browser for display enhancement purposes.
            </li>
            <li>
              You may download and print one copy of a reasonable number of pages of the Website or take a screenshot of
              the content for your own personal, non-commercial use and not for further reproduction, publication, or
              distribution.
            </li>
            <li>
              If we provide desktop, mobile or other applications for download, you may download a single copy to your
              computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound
              by our end user license agreement for such applications.
            </li>
          </ul>
          <p> </p>
          <p>You must not:</p>
          <ul>
            <li>Modify copies of any materials from this Website.</li>
            <li>
              Copy, sell, or in any way use any text, illustrations, photographs, video or audio sequences, graphics, or
              any other part of the Website, Content, or the Services except in the limited manner, for personal,
              non-commercial use only, described above.
            </li>
            <li>
              Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials
              from this site.
            </li>
            <li>
              You must not access or use for any commercial purposes any part of the Website or any Services or Content
              made available to You.
            </li>
          </ul>
          <p> </p>
          <p>
            Any use of the Software by you other than as required to navigate and to utilize the intended functionality
            offered through this Website is prohibited. You agree not to access the Services or Content by any means
            other than the interface provided by the Company through this Website for your use in accessing the Services
            and Content.
          </p>
          <p> </p>
          <p>
            If you print, copy, modify, download, or otherwise use or provide any other person with access to or content
            from any part of the Website or Services in breach of these Terms of Use, your right to use the Website and
            Services will cease immediately and you must, at our option, return or destroy any copies of the materials
            you have made. No right, title, or interest in or to the Website or Services, or any content on the Website
            is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the
            Website or Services not expressly permitted by these Terms of Use is a breach of these Terms of Use and may
            violate copyright, trademark, and other laws.
          </p>
          <p> </p>
          <p>TRADEMARKS</p>
          <p>
            TMA’s name and all related names, logos, product and service names, designs and slogans used in the Website
            or Services, unless otherwise noted, are trademarks of TMA or its affiliates or licensors. You must not use
            such marks without the prior written permission of TMA. All other names, logos, product and service names,
            designs and slogans on this Website are the trademarks of their respective owners.
          </p>
          <p> </p>
          <p>COPYRIGHT INFRINGEMENT – DMCA NOTICE</p>
          <p>
            {" "}
            The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe
            that material appearing on the Internet infringes their rights under US copyright law.
          </p>
          <p> </p>
          <p>
            If you believe in good faith that content or material on this website infringes a copyright owned by you,
            you or your agent may send a notice requesting that the material be removed, or access to it blocked (a
            “DMCA Notice”). The DMCA Notice must include the following information:{" "}
          </p>
          <ul>
            <li>
              (a) a physical or electronic signature of a person authorized to act on behalf of the owner of an
              exclusive right that is allegedly infringed;
            </li>
            <li>(b)&nbsp;identification of the copyrighted work claimed to have been infringed;</li>
            <li>
              (c) identification of the material that is claimed to be infringing or the subject of infringing activity;{" "}
            </li>
            <li>(d) the name, address, telephone number, and email address of the complaining party; </li>
            <li>
              (e) a statement that the complaining party has a good faith belief that use of the material in the manner
              complained of is not authorized by the copyright owner, its agent or the law; and{" "}
            </li>
            <li>
              (f) a statement that the information in the notification is accurate and, under penalty of perjury, that
              the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly
              infringed.{" "}
            </li>
          </ul>
          <p> </p>
          <p>
            If you believe in good faith that a DMCA Notice has been wrongly filed against you, the DMCA permits you to
            send us a counter-notice (“Counter-Notice”).{" "}
          </p>
          <p> </p>
          <p>
            All DMCA Notices and Counter-Notices must meet the then-current statutory requirements imposed by the DMCA.
            DMCA Notices and Counter-Notices should be sent to TMA at: info@TMAprecisionhealth.com. &nbsp;
          </p>
          <p> </p>
          <p>CONTACT BY TMA</p>
          <p>
            You agree to receive electronically all communications from us in connection with your use of the Website or
            Services, which may include emails, texts, mobile push notices, or messages on or through the Website or
            Services. You are encouraged to retain copies of these communications for your records. You agree that all
            digital agreements, notices, disclosures, and other communications that we provide to you meet any legal
            requirement that such communications would satisfy if they were provided to you in written hard copy.
          </p>
          <p> </p>
          <p>PROHIBITED USES</p>
          <p>
            As a condition of your use of the Services, you represent and warrant to TMA that you will not use the
            Website or Services for any purpose that is unlawful, misleading, malicious, or discriminatory, or
            prohibited by these terms, conditions, or notices. You agree not to use the Website, any content taken from
            it, or any copy of it, or the Services:
          </p>
          <ul>
            <li>
              In any way that violates or is prohibited by any applicable federal, state, local or international law or
              regulation (including, without limitation, U.S. export laws and regulations and any laws regarding the
              export of data or software to and from the US or other countries).
            </li>
            <li>
              To export or re-export the Services or any direct product thereof: (a) into (or to a national or resident
              of) any country that is subject to a U.S. general embargo or that is designated as a state sponsor of
              terrorism, or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals and
              Blocked Persons List or the U.S. Commerce Department’s Denied Parties List or Entities List. You represent
              that you are not located in or under the control of a national or resident of any such country or anyone
              on any such list.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or promotional material without our prior written
              consent, including any junk mail, chain letter or spam or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate TMA, a TMA employee, another user or any other person or entity
              (including, without limitation, by using e-mail addresses associated with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or
              which, as determined by us, may harm TMA or users of the Website or expose them to liability.
            </li>
          </ul>
          <p> </p>
          <p>Additionally, You agree not to:</p>
          <ul>
            <li>Solicit login information or access an account belonging to someone else. </li>
            <li>
              Collect users’ information, or otherwise access the Services, using automated means (such as harvesting
              bots, robots, spiders, or scrapers) without our permission.{" "}
            </li>
            <li>
              Use the Website or Services in any manner that could damage, disable, overburden, or impair the Services
              or interfere with any other party’s use and enjoyment of the Website or Services.
            </li>
            <li>
              Use any robot, spider or other automatic device, process or means to access the Website for any purpose,
              including monitoring, harvesting, scraping, or otherwise copying any of the material or information on the
              Website.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized
              purpose without our prior written consent.
            </li>
            <li>Use any device, software or routine that interferes with the proper working of the Website.</li>
            <li>
              Introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious or
              technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the
              server on which the Website is stored, or any server, computer or database connected to the website,
              including by soliciting passwords or personal information from any other person or by impersonating any
              other person or entity.
            </li>
            <li>Otherwise attempt to interfere with the proper working of the Website.</li>
          </ul>
          <p> </p>
          <p>RELIANCE ON INFORMATION PRESENTED</p>
          <p>
            All Content available through the Website and Services are provided for informational purposes only and is
            not intended as a substitute for professional medical advice, help, diagnosis, or treatment. Always seek the
            advice of your physician or other qualified health care provider with any questions you have regarding your
            medical care, and never disregard professional medical advice or delay seeking it because of something you
            have read on or via the Services. No information available on or via the Website or Services is intended to
            constitute a medical diagnosis or treatment. Reliance on any information appearing on the Website or
            Services, including but not limited to information provided by TMA personnel or by other users of the
            Website or Services, is solely at your own risk. TMA does not warrant the accuracy, completeness, timeliness
            or usefulness of the opinions, advice, content, services, or other information provided through the
            Services.
          </p>
          <p> </p>
          <p>SUBMISSIONS POLICY</p>
          <p>
            In the event you choose to submit, post, or otherwise provide TMA with feedback, suggestions, comments, or
            other communications (“Postings”), all Postings will be considered non-personal, non-confidential (except
            for Collected Information, as defined in the TMA Precision Health Consent to Data Use) and nonproprietary.
            You agree that you will not create any Postings that:
          </p>
          <ul>
            <li>interferes with or disrupts the Website or Services, or the operation of the Website or Services;</li>
            <li>
              defames, harasses, abuses, stalks, threatens, intimidates, or in any way infringes on the rights of
              others;
            </li>
            <li>
              incorporates in whole or in part unauthorized copyrighted materials or any other material that infringes
              on the intellectual property rights, trade secrets, or privacy of others;
            </li>
            <li>
              includes statements or material that violate any contractual or fiduciary rights, duties or agreements;
            </li>
            <li>includes statements or material that is bigoted, hateful, or racially offensive;</li>
            <li>
              includes statements or material that encourages criminal conduct or that would give rise to civil
              liability or otherwise violates any law or regulation in any jurisdiction;
            </li>
            <li>
              includes statements, images, or other material that contain vulgar, obscene, profane, or otherwise
              objectionable language or images that typically would not be considered socially or professionally
              responsible or appropriate in person;
            </li>
            <li>includes statements or material that harm minors;</li>
            <li>
              includes statements or material that impersonate any other person or entity, whether actual or fictitious,
              including employees and representatives of TMA;
            </li>
            <li>includes statements or material that misrepresent your affiliation with any entity and/or TMA;</li>
            <li>
              anything that violates the privacy or publicity rights of any other person, including displaying any
              personal identifying information of another individual;
            </li>
          </ul>
          <p> </p>
          <p>
            You agree that you will, at all times, provide true, accurate, current, and complete information when
            creating a Posting, including, but not limited to, when you provide information via an online form or via
            email. You represent that you have all necessary rights, consents, or permissions to make any Posting. You
            hereby grant TMA a perpetual, sublicensable, assignable, unrestricted, worldwide, royalty-free, irrevocable
            license to use, reproduce, display, perform, practice, modify, create derivative or collective works,
            transmit and distribute your Postings, in whole or in part, and including all intellectual property rights
            therein. You also acknowledge that all Postings are non-confidential and that TMA has no control over the
            extent to which any idea or information may be used by any party or person once it is posted or displayed.
            TMA assumes no responsibility for the deletion of or failure to store any Posting. TMA is not a publisher of
            any Posting and is merely functioning as an intermediary to enable you to provide and display a Posting.
          </p>
          <p> </p>
          <p>
            TMA is not responsible for screening, policing, editing, or monitoring your or another user’s Postings. TMA
            does not endorse or oppose any opinion or information provided by you or another user and does not make any
            representation with respect to, nor does it endorse the accuracy, completeness, timeliness, or reliability
            of, any advice, opinion, statement, or other material displayed, uploaded, or distributed by you or any
            other user. &nbsp;Nevertheless, TMA reserves the right to delete or take other action with respect to
            Postings (or parts thereof) that TMA believes in good faith violate these Terms of Use, are (or potentially
            are) unlawful or harmful to TMA or its services and goodwill, or for any other reason in TMA’s sole
            discretion. &nbsp;If you violate these Terms of Use, TMA may, in its sole discretion, delete the
            unacceptable content from your Posting, remove or delete the Posting in its entirety, issue you a warning,
            and/or terminate your access or use of the Website or the Services.
          </p>
          <p> </p>
          <p>THIRD PARTY CONTENT AND LINKS</p>
          <p>
            The Website may include or link to content provided by third parties. All statements and/or opinions
            expressed in such third-party content are solely the responsibility of the person or entity providing the
            content. TMA has no control over the contents of linked third party websites or resources and accepts no
            responsibility for them or for any loss or damage that may arise from your use of such websites or
            resources. If you access any third-party websites linked to the Website, you do so at your own risk and
            subject to such websites’ terms and conditions and privacy policies. The inclusion of any link to a
            third-party website does not necessarily imply endorsement by TMA of that website or the material or content
            contained therein. We are not responsible or liable to you or any third party for the content or accuracy of
            any information or services provided by third parties.
          </p>
          <p> </p>
          <p>FORCE MAJEURE</p>
          <p>
            TMA will not be liable for failure or delay in performing any Services to the extent that such failure or
            delay is due to causes beyond the reasonable control of TMA, such as acts of God, fire, strikes, embargo,
            terrorism, and regulatory governmental agencies.
          </p>
          <p> </p>
          <p>LIMITATION OF LIABILITY</p>
          <p>
            Reasonable efforts are taken to include accurate and up-to-date information on this Website. However, errors
            or omissions may occur. YOU UNDERSTAND AND AGREE THAT IN NO EVENT WILL TMA, ITS AFFILIATES OR THEIR
            LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
            UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
            DAMAGES,INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
            REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
            DATA, AND WHETHER CAUSED BYTORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
            FORESEEABLE, ARISING OUT OF OR IN CONNECTION WITH: (1) YOUR USE, OR INABILITY TO USE, THE SITE, ANY SITES
            LINKED TO IT; (2) ANY CONTENT OR SERVICES ON THE SITE OR SUCH OTHER SITES; (3) YOUR FAILURE TO KEEP YOUR
            PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL; (4) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
            SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED
            OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THIS SITE OR THE SERVICES; (5) UNAUTHORIZED ACCESS TO OR
            ALTERATION OF YOUR TRANSMISSIONS OR DATA; OR (6) THE STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THIS SITE
            OR IN CONNECTION WITH THE SERVICES.
          </p>
          <p> </p>
          <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
          <p> </p>
          <p>GOVERNING LAW AND ARBITRATION</p>
          <p>
            Any legal action or proceeding between You and TMA arising out of or related to these Terms of Use,
            including your use of and access to the Website, the Services, Collected Information (as defined in the TMA
            Precision Health Consent To Data Use), or Accumulated Information (as defined in the Privacy Policy)
            (collectively, “Dispute(s)”),shall be governed exclusively by the laws of the Commonwealth of Massachusetts,
            without regard to conflict of laws principles.{" "}
          </p>
          <p> </p>
          <p>
            You agree that any Dispute shall be finally and exclusively resolved by binding arbitration administered by
            the Boston, Massachusetts offices of JAMS pursuant to its Comprehensive Arbitration Rules and Procedures and
            in accordance with the Expedited Procedures in those Rules, and not by a lawsuit or resort to court process,
            except to the extent Massachusetts law provides for judicial review of final and binding arbitration
            resolution.  The arbitration shall be held in Boston, MA, U.S.A. before one (1) arbitrator. Judgement on an
            award may be entered in any court having jurisdiction. You further agree that the arbitrator, and not any
            federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to
            the interpretation, applicability, enforceability, or formation of this agreement to arbitrate. You
            understand and agree that this agreement to arbitrate binds you and anyone else who may have a Dispute.
          </p>
          <p> </p>
          <p>WAIVER &amp; SEVERABILITY</p>
          <p>
            No waiver by TMA of any term of condition set forth in these Terms of Use shall be deemed a further or
            continuing waiver of any such term or condition or waiver of any other term or condition, and any failure of
            TMA to assert any right or provision under these Terms of Use shall not constitute a waiver of such right or
            provision.
          </p>
          <p> </p>
          <p>
            The invalidity of all or any part of any provision of these Terms of Use shall not invalidate the remainder
            of these Terms of Use or the remainder of any section that can be given effect without such invalid
            provision.
          </p>
          <p> </p>
          <p>DISCLAIMER OF WARRANTIES</p>
          <p>
            YOUR USE OF THE SITE AND ITS CONTENT AND SERVICES IS AT YOUR OWN RISK. THE SITE THE SERVICES, AND CONTENT
            ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED.
            NEITHER TMA NOR ANY PERSON ASSOCIATED WITH TMA MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE
            FOREGOING, NEITHER TMA NOR ANYONE ASSOCIATED WITH TMA REPRESENTS OR WARRANTS THAT THE SITE, CONTENT, OR THE
            SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
            SITE ORTHE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE
            OR ANY SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p> </p>
          <p>
            TMA HEREBY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
            INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A
            PARTICULAR PURPOSE.{" "}
          </p>
          <p> </p>
          <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
          <p> </p>
          <p>INDEMNITY</p>
          <p>
            You agree to indemnify and hold harmless TMA, its directors, officers, affiliates, employees, agents,
            representatives, successors, licensors, successors, and assigns (the “Indemnified Parties”)harmless from and
            against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including
            actual attorney’s fees) arising out of or relating to Your violation of these Terms of Use or Your use of
            the Website, Content, or the Services.
          </p>
          <p> </p>
          <p>ASSIGNMENT</p>
          <p>
            You may not assign your responsibilities under these Terms of use to any other party. TMA may assign these
            Terms of Use to any future, directly or indirectly, affiliated company. TMA may also assign or delegate its
            rights and responsibilities under these Terms of Use to independent contractors or other third parties.{" "}
          </p>
          <p> </p>
          <p>CHANGES TO THE TERMS</p>
          <p>
            TMA may make changes to these Terms of Use from time to time. When these changes are made, TMA will make a
            new copy of the Terms of Use available here. You agree to review these Terms of Use periodically to be aware
            of such changes understand and agree that if you use or access the Website after the date on which the Terms
            of Use have changed, TMA will treat your use as acceptance of the updated Terms of Use.
          </p>
          <p> </p>
          <p>ENTIRE AGREEMENT</p>
          <p>
            The Terms of Use and other documents incorporated herein constitute the sole and entire agreement between
            You and TMA with respect to the Website, Content, and the Services and supersede all prior and
            contemporaneous understandings, agreements, representations and warranties, both written and oral, with
            respect to the Website.
          </p>
          <p> </p>
          <p>QUESTIONS &amp; COMMENTS</p>
          <p>This Website is operated by TMA Precision Health, 10 Derne Street, Boston, Massachusetts 02114.</p>
          <p> </p>
          <p>Any questions or comments related to these Terms of Use should be sent to info@TMAprecisionhealth.com.</p>
        </VStack>
      </VStack>
    </Box>
  );
}
