import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import EmptyState from "../EmptyState";

export default function CaseDescriptionAccordion({ description }: { description: string }) {
  return (
    <Box
      flex="2"
      background="white"
      maxWidth="100%"
      padding="41px"
      boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
      borderRadius="8px"
    >
      <Accordion allowToggle>
        <AccordionItem border="none">
          <AccordionButton
            background="brand.blue"
            borderRadius="4px"
            color="white"
            _hover={{ background: "brand.darkblue" }}
          >
            <Flex flexWrap="wrap" gap="1" alignItems="center" flex="1" textAlign="left">
              <Heading as="h2" color="grayOne" fontSize="20px" fontWeight="700">
                Case Description
              </Heading>
              <Text as="em" color="brand.gray2" isTruncated>
                (Click to expand / collapse)
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {description?.length ? (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              <EmptyState header="No data available" description="Sorry, there is no data available to show." />
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
