import {
  Box,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Stack,
  Button,
  Spinner,
  Container,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "src/components/Select";
import VirtualizedSelect from "src/components/VirtualizedSelect";
import { registerPatient } from "src/services/patient";
import diagnosisJson from "src/utils/data/diagnosis.data.json";

const diagnosisOptions = diagnosisJson
  .map((v) => ({ label: v.label, value: v.label }))
  .sort((a, b) => a.label.localeCompare(b.label));

const fetchCountries = async () => {
  try {
    const url = "https://countriesnow.space/api/v0.1/countries";
    const response = await axios.get(url);
    const responseData = response.data;
    const data = responseData.data;
    return data;
  } catch (error) {
    throw error;
  }
};

const ethnicities = [
  { label: "African-American", value: "African-American" },
  { label: "Asian", value: "Asian" },
  { label: "Caucasian", value: "Caucasian" },
  { label: "Hispanic", value: "Hispanic" },
  { label: "Native American", value: "Native American" },
  { label: "Abazin", value: "Abazin" },
  { label: "Abkhazian", value: "Abkhazian" },
  { label: "Adyghe", value: "Adyghe" },
  { label: "African", value: "African" },
  { label: "Afro-Caribbean", value: "Afro-Caribbean" },
  { label: "Albanian", value: "Albanian" },
  { label: "Algerian", value: "Algerian" },
  { label: "Altaic", value: "Altaic" },
  { label: "Arab", value: "Arab" },
  { label: "Arab American", value: "Arab American" },
  { label: "Arab-Berber", value: "Arab-Berber" },
  { label: "Other", value: "Other" },
];

const initialPatientState = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  ethnicity: "",
  gender: "",
  mainDiagnostic: "",
  birthDate: "",
  hasPreviousGeneticScreening: false,
};
export default function CreatePatientForm({ onSuccess, careTeam }: any) {
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [state, setState] = useState(initialPatientState);

  const handleChange = (name: string, value: string | boolean) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetState = () => {
    setState(initialPatientState);
    setMessage("");
    setErrMessage("");
  };

  const handleSignup = async (e: any) => {
    e.preventDefault();
    const data = {
      name: state.firstName,
      familyName: state.lastName,
      email: state.email,
      gender: state.gender,
      ethnicity: state.ethnicity,
      birthDate: new Date(state.birthDate).toISOString(),
      mainDiagnosis: state.mainDiagnostic,
      careTeam: careTeam,
    };

    try {
      setLoading(true);
      const response = await registerPatient(data);
      setLoading(false);

      if (response.error) {
        setErrMessage(response.error || "Error creating patient. Please try again.");
        return;
      } else {
        setMessage(
          "The patient has been created successfully. An email has been sent to the patient with the credentials to login on the platform."
        );
        onSuccess?.(response);
      }
    } catch (error: any) {
      console.error(error);
      const { data } = error?.response;
      let message = data?.error || "Error creating patient. Please try again.";
      if (data?.code === "patient_already_exists") {
        message =
          "A patient with this email already exists. We've sent an email to the patient so that the patient can add you as care team member.";
      }
      setErrMessage(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries()
      .then((data) => {
        setCountries(
          data.map((item: any) => ({
            label: item.country,
            value: item.country,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (message) {
    return (
      <Container>
        <Stack spacing="8">
          <Alert status="success">
            <AlertIcon />
            {message}
          </Alert>

          <Button colorScheme="blue" onClick={resetState}>
            Create another patient
          </Button>
        </Stack>
      </Container>
    );
  }

  if (errMessage) {
    return (
      <Container>
        <Stack spacing="8">
          <Alert status="error">
            <AlertIcon />
            {errMessage}
          </Alert>

          <Button colorScheme="blue" onClick={resetState}>
            Go Back
          </Button>
        </Stack>
      </Container>
    );
  }

  return (
    <Stack maxHeight="100%" overflow="scroll">
      <Box as="form" rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8} onSubmit={handleSignup}>
        <Stack spacing={4}>
          <HStack>
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                value={state.firstName}
                required
                onChange={(e) => handleChange("firstName", e.target.value)}
              />
            </FormControl>
            <FormControl id="lastName">
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                value={state.lastName}
                required
                onChange={(e) => handleChange("lastName", e.target.value)}
              />
            </FormControl>
          </HStack>
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              value={state.email}
              required
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Birth Date</FormLabel>
            <Input
              required
              placeholder="Select birth date"
              size="md"
              type="date"
              value={moment(state?.birthDate).format("YYYY-MM-DD")}
              onChange={(e) => handleChange("birthDate", e.target.value)}
            />
          </FormControl>

          <FormControl id="previous-genetic-screening" isRequired>
            <FormLabel>Gender at birth</FormLabel>
            <Select
              options={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
              ]}
              required
              onChange={({ value }: any) => handleChange("gender", value)}
            />
          </FormControl>

          <FormControl id="country" isRequired>
            <FormLabel>Country</FormLabel>
            <Select
              options={countries}
              required
              onChange={({ value }: any) => handleChange("country", value)}
            />
          </FormControl>

          <FormControl id="ethnicity" isRequired>
            <FormLabel>Ethnicity</FormLabel>
            <Select
              options={ethnicities}
              required
              onChange={({ value }: any) => handleChange("ethnicity", value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Diagnosis *</FormLabel>
            <VirtualizedSelect
              name="diagnosis"
              options={diagnosisOptions}
              onChange={({ value }: any) => handleChange("mainDiagnostic", value)}
              required
              placeholder="Select or search diagnosis.."
            />
          </FormControl>

          <FormControl id="previous-genetic-screening" isRequired>
            <FormLabel>Has patient had any genetic screening done previously?</FormLabel>
            <Select
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              required
              onChange={({ value }: any) =>
                handleChange("hasPreviousGeneticScreening", value === "yes")
              }
            />
          </FormControl>

          <Stack spacing={10} pt={2}>
            <Button
              loadingText="Submitting"
              size="lg"
              type="submit"
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
              isDisabled={loading}
            >
              {loading ? "Creating..." : "Create Patient"}
              {loading && <Spinner />}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
