import { Box, Heading, Text } from "@chakra-ui/react";
import { CallBackProps } from "react-joyride";

export const getTourSteps = (user: any) => {
  const steps = [
    {
      target: "#settings-page",
      content: (
        <Box>
          <Heading size="md">Go to Settings</Heading>
          <Text>Click to go to the Settings page, then click "Next".</Text>
        </Box>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
    },
    {
      target: "#settings-form",
      content: (
        <Box>
          <Heading size="md">Complete your settings</Heading>
          <ol style={{ textAlign: "left", fontSize: "14px" }}>
            <li>Click "Upload Image" to upload a profile image</li>
            <li>Fill out the form to complete your settings.</li>
            <li>Click the "Update profile" button below the form.</li>
            <li>Then click "Next".</li>
          </ol>
        </Box>
      ),

      styles: {
        buttonBack: {
          display: "none",
        },
      },

      disableBeacon: true,
      disableBackButton: true,
      disableOverlayClose: true,
      spotlightClicks: true,
    },
    {
      target: "#dashboard-page",
      content: (
        <Box>
          <Heading size="md">Go to Dashboard</Heading>
          <Text>Click to go to the Dashboard page, then click "Next".</Text>
        </Box>
      ),
      disableOverlayClose: true,
      spotlightClicks: true,
    },
    {
      target: "#edit-vitals",
      content: (
        <Box>
          <Heading size="md">Add your vitals</Heading>
          <Text>Click the edit icon to add your vitals information. Once added, click "Next".</Text>
        </Box>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
    },
    {
      target: "#edit-diagnosis",
      content: (
        <Box>
          <Heading size="md">Add your diagnosis</Heading>
          <Text>Click the edit button to add your diagnosis. Once added, click "Next".</Text>
        </Box>
      ),
      disableOverlayClose: true,
      spotlightClicks: true,
    },
  ];

  // if (user.role !== "patient") {
  //   steps.shift();
  //   steps.shift();
  // }

  return steps;
};

export const getJoyRideCallback =
  ({ location, navigate, setState, stepIndex, user }) =>
  (data: CallBackProps) => {
    const { action, status, type, step } = data;

    if (type === "step:after" && action === "next") {
      if (["#settings-page", "#settings-form"].includes(step?.target as string)) {
        if (!location.pathname.match("/settings")) navigate(`/patient/${user.username}/settings`);
      }
      if (["#dashboard-page"].includes(step?.target as string)) {
        if (!location.pathname.match("/dashboard")) navigate(`/patient/${user.username}/dashboard`);
      }
      return setState({ stepIndex: stepIndex + 1 });
    }

    if (type === "step:after" && action === "prev") {
      if (["#settings-page", "#settings-form"].includes(step?.target as string)) {
        if (location.pathname.match("/settings")) navigate(`/patient/${user.username}/dashboard`);
      }
      if (["#dashboard-page"].includes(step?.target as string)) {
        if (location.pathname.match("/dashboard")) navigate(`/patient/${user.username}/settings`);
      }
      return setState({ stepIndex: stepIndex - 1 });
    }

    if (type === "tour:end" && status === "finished") {
      return setState({ stepIndex: 0, run: false });
    }

    if (action === "close") {
      return setState({ run: false, stepIndex: 0 });
    }
  };
