import { Accordion } from "@chakra-ui/react";
import EmptyState from "src/components/EmptyState";
import MedicationAccordionItem from "./MedicationItem";

interface Props {
  data: RareCare.Medication[];
}

const Medications = ({ data }: Props) => {
  return data?.length ? (
    <Accordion
      defaultIndex={data.map((_, i) => i)}
      allowMultiple
      height="95%"
      overflow="scroll"
      marginTop="33px"
      marginBottom="50px"
    >
      {data.map((item) => (
        <MedicationAccordionItem key={item.Medication} medication={item} />
      ))}
    </Accordion>
  ) : (
    <EmptyState header="No data available" description="Sorry, there is no data available to show." />
  );
};

export default Medications;
