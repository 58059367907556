import { FunctionComponent } from "react";
import { Box, Button, Flex, Grid, HStack } from "@chakra-ui/react";
import MedicalJourneyTimeline from "src/components/DashboardCards/ActivityTimeline";
import CaseActions from "src/components/DashboardCards/CaseActions";
import CaseSummary from "src/components/DashboardCards/CaseSummary";
import MyMedications from "src/components/DashboardCards/MyMedications";
import EmptyState from "src/components/EmptyState";
import Loader from "src/components/Loader";
import AccountProgressStepper from "src/components/AccountProgressStepper";
import AppSearch from "src/components/AppSearch";
import CaseDescriptionAccordion from "src/components/CaseDescriptionAccordion";
import { useAuth } from "src/contexts/auth.context";
import { usePatientContext } from "src/contexts/patient.context";
import PatientConsentFormModal from "src/components/PatientConsentFormModal";

const Dashboard: FunctionComponent = () => {
  const { signOut } = useAuth();
  const { error, loading, patientData, caseData } = usePatientContext();

  if (error) {
    return (
      <Grid placeItems="center" width="100vw" height="100vh" background="white">
        <EmptyState
          header="An error happened"
          description={"Oops! An error happened. Try again later"}
        />
        <HStack>
          <Button onClick={() => window.location.reload()}>Reload</Button>
          <Button onClick={() => signOut()} colorScheme="red">
            Log out
          </Button>
        </HStack>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Box display="grid" placeItems="center" width="100%" height="100%">
        <Loader />
      </Box>
    );
  }

  return (
    <Flex gap="16px" direction="column" marginBottom="100px">
      <PatientConsentFormModal patientData={patientData} />
      <AppSearch />
      <AccountProgressStepper patient={patientData} />

      <Flex direction={["column", "column", "column", "row"]} flexWrap="wrap" gap="16px">
        <CaseSummary patient={patientData} patientCase={caseData} />
        <CaseActions />
      </Flex>

      <CaseDescriptionAccordion description={caseData.description || ""} />

      <Flex gap="16px" direction={["column", "column", "column", "column", "row"]}>
        <MyMedications patient={patientData} patientCase={caseData} />
        <MedicalJourneyTimeline patient={patientData} patientCase={caseData} />
      </Flex>
    </Flex>
  );
};

export default Dashboard;
