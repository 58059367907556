import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import Loader from "src/components/Loader";
import Select from "src/components/Select";
import { bloodTypes } from "src/constants/common";
import { updatePatient } from "src/services/patient";
import {
  centimeterToFeet,
  feetToCentimeter,
  gramToLibs,
  LibsToGram,
  roundToTwoDecimal,
} from "src/utils";
import { usePatientContext } from "src/contexts/patient.context";

interface VitalsDrawerProps {
  initialValue: any;
  isOpen: boolean;
  patient: any;
  onClose: () => void;
}

export default function VitalsEditDrawer({
  initialValue,
  isOpen,
  patient,
  onClose: closeDrawer,
}: VitalsDrawerProps) {
  const patientContext = usePatientContext();
  const toast = useToast({ position: "top-right" });
  const initialWeightValue = initialValue ? gramToLibs(initialValue.weight) : "";
  const initialHeightValue = initialValue ? centimeterToFeet(initialValue.height) : "";
  const initialBloodTypeOption = initialValue
    ? { label: initialValue.bloodType, value: initialValue.bloodType }
    : null;

  const blodTypeOptions = useMemo(() => {
    return bloodTypes.map((type) => ({ label: type, value: type }));
  }, []);

  const queryClient = useQueryClient();
  const patientMutation = useMutation(updatePatient, {
    onSuccess: (response) => {
      queryClient.setQueryData(["patientByUserId", patient.userId], response.data);
      patientContext.setPatientData(response.data);
      closeDrawer();
      toast({
        position: "top-right",
        status: "success",
        title: "Profile successfully updated!",
      });
    },
    onError: (err) => {
      console.error(err);
      toast({
        position: "top-right",
        status: "error",
        title: "Profile update failed",
      });
    },
  });

  const updating = patientMutation.isLoading;

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());

    const bloodType = formDataObject.bloodType;
    const heightInFeet = roundToTwoDecimal(Number(formDataObject.heightInFeet));
    const weightInLibs = roundToTwoDecimal(Number(formDataObject.weightInLibs));

    const vitals = {
      bloodType: bloodType,
      height: String(Math.round(feetToCentimeter(heightInFeet))),
      weight: String(Math.round(LibsToGram(weightInLibs))),
    };

    patientMutation.mutate({ patientId: patient.id, data: { vitals } });
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit vitals</DrawerHeader>

        {updating ? <Loader /> : null}

        <form onSubmit={handleSubmit}>
          <DrawerBody>
            <VStack gap="16px" mb="50px">
              <FormControl>
                <FormLabel>Blood type *</FormLabel>
                <Select
                  name="bloodType"
                  defaultValue={initialBloodTypeOption}
                  options={blodTypeOptions}
                  required
                  placeholder="Select blood type.."
                  isDisabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Select your blood type.</em>
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Weight (in lbs)*</FormLabel>
                <Input
                  name="weightInLibs"
                  type="number"
                  step="any"
                  defaultValue={initialWeightValue}
                  required
                  min={2.2}
                  max={1653.47}
                  placeholder="Type your weight here..."
                  disabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Enter your weight in Lbs. i.e.: 297.5</em>
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Height (in fts)*</FormLabel>
                <Input
                  name="heightInFeet"
                  type="number"
                  step="any"
                  defaultValue={initialHeightValue}
                  required
                  min={0.32}
                  max={9.9}
                  placeholder="Type your height here..."
                  disabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Enter your weight in fts. i.e.: 5.5</em>
                </FormHelperText>
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeDrawer} disabled={updating}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" disabled={updating}>
              Save
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
