import { AddIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { MdOutlineVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CareTeamLayout from "src/components/CareTeamLayout";
import CreatePatientForm from "src/components/CreatePatientForm";
import DnaSampleCollectedModal from "src/components/DnaSampleCollectedModal";
import Loader from "src/components/Loader";
import AppTable from "src/components/Table";
import { useAuth } from "src/contexts/auth.context";
import { getCareTeamMemberPatients } from "src/services/care-team";

const CareTeamDashboard = () => {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dnaSampleDisclosure = useDisclosure();
  const [careTeamMember, setCareTeamMember] = useState<any>(null);
  const [selectedPatient, setSelectedPatient] = useState<any>(null);

  const navigate = useNavigate();

  const {
    data: patients,
    refetch: refetchPatients,
    status,
    isError,
  } = useQuery(["care-team-patients", user.username], () => {
    return getCareTeamMemberPatients(user.username);
  });

  const isLoading = status === "loading";

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Text>Something went wrong. Try reloading the page!</Text>;
  }

  const columns = [
    {
      Header: "Patient Full Name",
      accessor: "name",
      Cell: ({ row }: any) => {
        const { userId, name, familyName } = row.original;
        return (
          <Button
            variant="link"
            onClick={() => {
              navigate(`/patient/${userId}/dashboard`);
            }}
          >
            <Text color="blue.500" textDecoration="underline">{`${name} ${familyName}`}</Text>
          </Button>
        );
      },
    },
    {
      Header: "Family Name",
      accessor: "familyName",
    },
    {
      Header: "Kit Serial Number",
      accessor: "kitSerialNumber",
      Cell: ({ row }: any) => {
        const { kitSerialNumber } = row.original;
        return <Text>{kitSerialNumber || "N/A"}</Text>;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }: any) => {
        const { email } = row.original;
        return <Text>{email || "N/A"}</Text>;
      },
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: ({ row }: any) => {
        const { gender } = row.original;
        return <Text>{gender || "N/A"}</Text>;
      },
    },
    {
      Header: "Diagnosis",
      accessor: "mainDiagnosis",
      Cell: ({ row }: any) => {
        const { mainDiagnosis } = row.original;
        return <Text>{mainDiagnosis || "N/A"}</Text>;
      },
    },
    {
      Header: "Ethnicity",
      accessor: "ethnicity",
      Cell: ({ row }: any) => {
        const { ethnicity } = row.original;
        return <Text>{ethnicity || "N/A"}</Text>;
      },
    },
    {
      Header: "Medical Record Status",
      Cell: ({ row }: any) => {
        const { profileCompletion } = row.original;
        return <ProfileCompletionPopover profileCompletion={profileCompletion} />;
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }: any) => {
        return (
          <Menu>
            <MenuButton as={IconButton} icon={<FiSettings />} />
            <MenuList>
              <MenuItem
                onClick={() => {
                  dnaSampleDisclosure.onOpen();
                  setSelectedPatient(row.original);
                }}
              >
                DNA Sample Collected
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  return (
    <CareTeamLayout setCareTeamMember={setCareTeamMember}>
      <Heading as="h1" fontSize="20px" color="brand.gray1" mb="4">
        Patients
      </Heading>
      <HStack justifyContent="flex-end" mb="1">
        <Button aria-label="add-patient" colorScheme="blue" variant="link" onClick={onOpen}>
          Add Patient <AddIcon width="3" marginLeft="1" />
        </Button>
      </HStack>
      <AppTable
        columns={columns}
        data={patients || []}
        initialState={{
          hiddenColumns: ["familyName"],
        }}
        withSearch
      />

      {/* DNA Sample Collected Modal */}
      <DnaSampleCollectedModal
        isOpen={dnaSampleDisclosure.isOpen}
        onClose={dnaSampleDisclosure.onClose}
        patient={selectedPatient}
        onSubmissionCompleted={() => {
          refetchPatients();
        }}
      />

      {/* Add patient Drawer  */}
      <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
        <DrawerContent>
          <DrawerHeader>
            <Heading as="h3" fontSize="20px" color="brand.gray1">
              Add Patient
            </Heading>
          </DrawerHeader>
          <DrawerCloseButton />
          <CreatePatientForm
            careTeam={{
              id: careTeamMember?.id,
              email: careTeamMember?.email,
            }}
            onSuccess={(response: any) => {
              refetchPatients();
            }}
          />
        </DrawerContent>
      </Drawer>
    </CareTeamLayout>
  );
};

function ProfileCompletionPopover({ profileCompletion }: any) {
  const steps = [
    {
      description: "Consent agreed & Signed",
      completed: profileCompletion.consentAgreed,
    },
    {
      description: "Contact info provided",
      completed: profileCompletion.contactInfoProvided,
    },
    {
      description: "Self input diagnosis",
      completed: profileCompletion.selfInputDiagnosis,
    },
    {
      description: "PDF input + EMR pull Consent",
      completed: profileCompletion.emrPdfUploaded,
    },
    {
      description: "WGS consent agreed",
      completed: profileCompletion.wgsConsentAgreed,
    },
    {
      description: "WGS shipped to Patient",
      completed: profileCompletion.wgsKitShipped,
    },
    {
      description: "WGS report ready",
      completed: profileCompletion.wgsReportReady,
    },
    {
      description: "GC consent agreed",
      completed: profileCompletion.geneticCounselingConsentAgreed,
    },
    {
      description: "GC appointment booked",
      completed: profileCompletion.GeneticCounselingAppointmentBooked,
    },
    {
      description: "GC appointment completed",
      completed: profileCompletion.GeneticCounselingAppointmentCompleted,
    },
    {
      description: "GC report ready",
      completed: profileCompletion.GeneticCounselingReportReady,
    },
  ];
  return (
    <Box position="relative">
      <Popover>
        <PopoverTrigger>
          <Badge variant="subtle" colorScheme="green" cursor="pointer">
            {steps.reduce((acc, step) => {
              if (step.completed) acc = step.description;
              return acc;
            }, "") || "Consent agreed"}
          </Badge>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Progress Status</PopoverHeader>
          <PopoverBody>
            {steps.map((step) => (
              <Box key={step.description}>
                <HStack>
                  <Text as="span" color={step.completed ? "brand.blue" : "gray.400"}>
                    {step.description}
                  </Text>
                  {step.completed ? <MdOutlineVerified color="green" /> : null}
                </HStack>
              </Box>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default CareTeamDashboard;
