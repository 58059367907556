import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, VStack } from "@chakra-ui/react";

interface Props {
  medication: RareCare.Medication;
}

const MedicationItem = ({ medication }: Props) => {
  return (
    <AccordionItem>
      <AccordionButton
        bg="brand.blue"
        borderRadius="4px"
        _hover={{
          background: "#1f3d6b",
        }}
      >
        <Box
          as="h2"
          flex="1"
          textAlign="left"
          fontSize="xl"
          color="white"
          fontWeight="bold"
          padding="10px"
          height="100%"
        >
          {medication.Medication} ({medication.Strength}/{medication.Form})
        </Box>
        <AccordionIcon color="white" />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Box>
          <VStack as="ul" align="left" marginLeft="30px">
            <ListItem title="Name" description={medication.Medication} />
            <ListItem title="Start date" description={medication.StartDate} />
            <ListItem title="End date" description={medication.EndDate} />
            <ListItem title="Dosage form" description={medication.Form} />
            <ListItem title="Strength" description={medication.Strength} />
            <ListItem title="Frequency" description={medication.Frequency} />
            <ListItem title="Route" description={medication.Route} />
            <ListItem title="Extra info" description={medication.ExtraInfo} />
          </VStack>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

function ListItem({ title, description }: { title: string; description: string }) {
  return (
    <Box as="li">
      <Box as="span" fontWeight="bold" width="90px" display="inline-block">
        {title}
      </Box>
      <Box as="span">: {description}</Box>
    </Box>
  );
}

export default MedicationItem;
