import MedicationsTimeline from "../../components/Medications/MedicationsTimeline";
import { Box, Heading, HStack } from "@chakra-ui/react";
import EmptyState from "../EmptyState";
// import Medications from "./Medications";

const MyMedications = ({ patient, patientCase }: any) => {
  if (!patient || !patientCase) {
    return null;
  }

  return (
    <Box
      background="white"
      padding="40px"
      borderRadius="8px"
      height={"500px"}
      width={"100%"}
      overflow="hidden"
    >
      <HStack justify="space-between">
        <Heading color="grayOne" fontSize="20px" fontWeight="700">
          My Medications
        </Heading>
      </HStack>

      {patientCase.medications?.length ? (
        <>
          <MedicationsTimeline meds={patientCase.medications} />
          {/* <Medications data={patientCase.medications} /> */}
        </>
      ) : (
        <EmptyState
          header="No data available"
          description="Sorry, there is no data available to show."
        />
      )}
    </Box>
  );
};

export default MyMedications;
