import { Amplify, Auth } from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  user: RareCare.user;
  setUser: React.Dispatch<React.SetStateAction<RareCare.user>>;
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => void;
  updateProfile: (attributes: Partial<RareCare.user>) => Promise<Result>;
  onForgotPasswordClick: (username: string) => Promise<any>;
  resetPassword: (username: string, code: string, password: string) => Promise<any>;
}

interface Result {
  success: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ["email", "profile", "openid"],
      redirectSignIn: process.env.REACT_APP_URL + "/dashboard",
      redirectSignOut: process.env.REACT_APP_URL,
      responseType: "token",
    },
  },
});

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const mapUserAttributes = (attributes: any): RareCare.user => {
  return {
    username: attributes.sub,
    email: attributes.email,
    name: attributes.given_name,
    familyName: attributes.family_name,
    picture: attributes.picture,
    birthdate: attributes.birthdate,
    phoneNumber: attributes.phone_number,
    address: attributes.address,
    role: attributes["custom:patient"] === "1" ? "patient" : "care-team-member",
  };
};

export const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<RareCare.user>({} as RareCare.user);

  useEffect(() => {
    if (user.username) return;
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((result) => {
        const authedUser = mapUserAttributes(result.attributes);
        setUser(authedUser);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setUser({} as RareCare.user);
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, [user.username]);

  const signIn = async (username: string, password: string) => {
    try {
      setIsLoading(true);
      const result = await Auth.signIn(username, password);
      setIsAuthenticated(true);
      setUser(mapUserAttributes(result.attributes));
      return { success: true, message: "Login Success" };
    } catch (error: any) {
      const message = error?.message || "Login Failed: Wrong username or password";
      return { success: false, message };
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setIsLoading(true);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await cognitoUser.signOut();
      setUser({} as RareCare.user);
      setIsAuthenticated(false);
      setIsLoading(false);
      window.location.reload();
    } catch (error: any) {
      console.error(error);
    }
  };

  const updateProfile = async (attributes: Partial<RareCare.user>) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const updatedAttributes = {
        name: attributes.name,
        given_name: attributes.name,
        email: attributes.email,
        family_name: attributes.familyName,
        birthdate: attributes.birthdate,
        phone_number: attributes.phoneNumber,
        address: attributes.address,
      };

      await Auth.updateUserAttributes(cognitoUser, updatedAttributes);
      const result = await Auth.currentUserInfo();
      setUser(mapUserAttributes(result.attributes));
      return { success: true, message: "Profile updated successfully" };
    } catch (error: any) {
      const message = error?.message || "Profile update failed.";
      return { success: false, message };
    }
  };

  const onForgotPasswordClick = async (username: string) => {
    try {
      const data = await Auth.forgotPassword(username);
      return data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

  const resetPassword = async (username: string, code: string, password: string) => {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, password);
      return data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    username: user.username,
    user,
    setUser,
    signIn,
    signOut,
    updateProfile,
    onForgotPasswordClick,
    resetPassword,
  };
};
