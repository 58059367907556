type ProfileCompletion = {
  consentAgreed: boolean;
  contactInfoProvided: boolean;
  selfInputDiagnosis: boolean;
  emrPdfUploaded: boolean;
  wgsConsentAgreed: boolean;
  wgsKitShipped: boolean;
  wgsKitReceived: boolean;
  wgsReportReady: boolean;
  geneticCounselingConsentAgreed: boolean;
  geneticCounselingAppointmentBooked: boolean;
  geneticCounselingAppointmentCompleted: boolean;
  geneticCounselingReportReady: boolean;
};

export const getSteps = (profileCompletion: ProfileCompletion) => {
  const stepObjects = [
    {
      title: "Account",
      tasks: [
        {
          key: "ACCOUNT_CONSENT_AGREED",
          title: "Consent agreed & Signed",
          completed: profileCompletion.consentAgreed,
        },
        {
          key: "ACCOUNT_CONTACT_INFO",
          title: "Contact Info",
          completed: profileCompletion.contactInfoProvided,
        },
        {
          key: "ACCOUNT_SELF_INPUT_DIAGNOSIS",
          title: "Self input diagnosis",
          completed: profileCompletion.selfInputDiagnosis,
        },
        {
          key: "ACCOUNT_PDF_INPUT",
          title: "PDF Input + EMR pull Consent",
          completed: profileCompletion.emrPdfUploaded,
        },
      ],
    },
    {
      title: "WGS",
      tasks: [
        { key: "WGS_CONSENT", title: "WGS Consent", completed: profileCompletion.wgsConsentAgreed },
        {
          key: "WGS_KIT_SHIPPED",
          title: "Shipped to Patient",
          completed: profileCompletion.wgsKitShipped,
        },
        {
          key: "WGS_KIT_RECEIVED",
          title: "WGS Received",
          completed: profileCompletion.wgsKitReceived,
        },
        {
          key: "WGS_REPORT_READY",
          title: "WGS report ready",
          completed: profileCompletion.wgsReportReady,
        },
      ],
    },
    {
      title: "Genetic counselling",
      tasks: [
        {
          key: "GENETIC_COUNSELING_CONSENT",
          title: "GC Consent",
          completed: profileCompletion.geneticCounselingConsentAgreed,
        },
        {
          key: "GENETIC_COUNSELING_APPOINTMENT_BOOKED",
          title: "GC Appointment booked",
          completed: profileCompletion.geneticCounselingAppointmentBooked,
        },
        {
          key: "GENETIC_COUNSELING_APPOINTMENT_COMPLETED",
          title: "GC Appointment completed",
          completed: profileCompletion.geneticCounselingAppointmentCompleted,
        },
        {
          key: "GENETIC_COUNSELING_REPORT_READY",
          title: "GC Report ready",
          completed: profileCompletion.geneticCounselingReportReady,
        },
      ],
    },
  ];

  return stepObjects;
};

type StepsObject = ReturnType<typeof getSteps>;

export const stepsReducer = (count: number, step: StepsObject[number]) => {
  if (step.tasks.every((task) => task.completed)) {
    count = count + 1;
    return count;
  }
  return count;
};
