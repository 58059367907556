import { Box, Button, HStack, IconButton, Input, Text, Tooltip, VStack } from "@chakra-ui/react";
import { ChangeEvent, useRef, useState } from "react";
import { FiUploadCloud, FiXCircle } from "react-icons/fi";

interface FileUploadProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onInputClear?: () => void;
  accept: string;
  title: string;
  disabled?: boolean;
}

export default function FileUploadInput({
  accept,
  title,
  disabled,
  onChange,
  onInputClear,
}: FileUploadProps) {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setFile(file);
    onChange(event);
  };

  return (
    <Box height="150px" width="100%" borderRadius="8px" border="1px solid lightgray">
      <Button
        isDisabled={disabled}
        variant="ghost"
        width="100%"
        height="100%"
        _hover={{ background: "gray.50" }}
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        <VStack>
          <IconButton aria-label="Upload file" icon={<FiUploadCloud size={20} />} />
          <Box as="span" color="brand.darkblue">
            {title}
          </Box>
          <Text fontWeight="300" color="brand.gray1">
            Accepts: {accept}
          </Text>
          {file ? (
            <HStack>
              <Text fontWeight="400">Selected file: {file.name}</Text>
              <Tooltip label="Remove file">
                <IconButton
                  variant="ghost"
                  size="sm"
                  aria-label="remove file"
                  icon={<FiXCircle color="red" />}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!fileInputRef.current) return;
                    fileInputRef.current.value = "";
                    setFile(null);
                    onInputClear?.();
                  }}
                ></IconButton>
              </Tooltip>
            </HStack>
          ) : null}
        </VStack>
      </Button>
      <Input
        isDisabled={disabled}
        display="none"
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={handleChange}
      />
    </Box>
  );
}
