import { Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FiSettings } from "react-icons/fi";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import AppTable from "src/components/Table";
import { usePatientContext } from "src/contexts/patient.context";
import { getPatientCareTeam, removeCareTeamMemberFromPatient } from "src/services/patient";

const PatientCareTeam = () => {
  const { patientData } = usePatientContext();
  const params = useParams();
  const patientUserId = params.patientId as string;

  const {
    data: patientsCareTeamMembers,
    refetch: refetchCareTeamMembers,
    status,
    isError,
  } = useQuery(["patient-care-team-member", patientUserId], () => {
    return getPatientCareTeam(patientUserId);
  });

  const removeCareTeamMutation = useMutation(removeCareTeamMemberFromPatient, {
    onSuccess: (data) => {
      refetchCareTeamMembers();
    },
    onError: (error) => {
      console.error(error);
      alert("Something went wrong. Try again later!");
    },
  });

  const isLoading = status === "loading";

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Text>Something went wrong. Try reloading the page!</Text>;
  }

  const columns = [
    {
      Header: "First Name",
      accessor: "name",
    },
    {
      Header: "Family Name",
      accessor: "familyName",
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }: any) => {
        const { title } = row.original;
        return <Text>{title || "N/A"}</Text>;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }: any) => {
        const { email } = row.original;
        return <Text>{email || "N/A"}</Text>;
      },
    },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      Cell: ({ row }: any) => {
        const { phoneNumber } = row.original;
        return <Text>{phoneNumber || "N/A"}</Text>;
      },
    },
    {
      Header: "Specialty",
      accessor: "specialty",
      Cell: ({ row }: any) => {
        const { specialty } = row.original;
        return <Text>{specialty || "N/A"}</Text>;
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }: any) => {
        return (
          <Menu>
            <MenuButton as={IconButton} icon={<FiSettings />} />
            <MenuList>
              <MenuItem
                onClick={() => {
                  removeCareTeamMutation.mutate({
                    patientId: patientData.id,
                    careTeamMemberId: row.original.id,
                  });
                }}
              >
                Remove
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  return (
    <>
      <Heading as="h1" fontSize="20px" color="brand.gray1" mb="4">
        Care team members
      </Heading>
      <AppTable
        columns={columns}
        data={patientsCareTeamMembers || []}
        initialState={{}}
        emptyMessage="No care team members found. You can add care team members by clicking on the 'Add care team member' button on your dashboard."
        withSearch
      />
    </>
  );
};

export default PatientCareTeam;
