import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { FiBell, FiHome } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { getCareTeamMemberByUserId } from "src/services/care-team";
import CtmConsentFormModal from "../CtmConsentFormModal";
import MobileNav from "./MobileNav";
import NavItem from "./NavItem";

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const getLinkItems = (userId: string) => [
  { name: "Home", href: `/care-team-member/${userId}/dashboard`, icon: FiHome },
  { name: "Invites", href: `/care-team-member/${userId}/invites`, icon: FiBell },
  // { name: "Settings", href: `/care-team-member/${userId}/settings`, icon: FiSettings },
];

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const params = useParams();
  const { ctmId } = params;

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="xl" fontFamily="monospace" fontWeight="bold">
          Care Team
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {getLinkItems(ctmId || "").map((link) => (
        <NavItem href={link.href} key={link.name} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const CareTeamLayout = ({ children, setCareTeamMember }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();
  const { ctmId } = params;

  const { data, isLoading, refetch, isError } = useQuery(["care-team-member-data", ctmId], () =>
    getCareTeamMemberByUserId(ctmId as string)
  );

  const { careTeamMember, invitesCount } = data || {};

  useEffect(() => {
    if (!careTeamMember) return;
    setCareTeamMember?.(careTeamMember);
  }, [careTeamMember, setCareTeamMember]);

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Something went wrong</div>;

  return (
    <Box minH="100vh" bg={"gray.100"}>
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} careTeamMemberData={careTeamMember} invitesCount={invitesCount} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <CtmConsentFormModal ctmData={careTeamMember} onSignComplete={refetch} />
        {children}
      </Box>
    </Box>
  );
};

export default CareTeamLayout;
