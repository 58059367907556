import axios from "axios";

export async function extractICD10FromDescription(description: string[]): Promise<any> {
  const params = {
    CaseDescription: description.join(" "),
  };

  return axios
    .post(process.env.REACT_APP_RARECARE_API + "/analytics/description/process", params)
    .then((res) => {
      if (res.data.Error) {
        throw new Error("Error extracting ICD10 from description");
      } else {
        return res.data.Payload;
      }
    })
    .catch((err) => {
      alert(err.message);
      return [];
    });
}

export async function extractHPOFromICD10(icd10: any): Promise<any> {
  let terms: string[] = [];
  if (icd10.Symptoms != null) {
    icd10.Symptoms.forEach((item: any) => {
      terms.push(item.Text);
    });
  }
  if (icd10.Diagnoses != null) {
    icd10.Diagnoses.forEach((item: any) => {
      terms.push(item.Text);
    });
  }
  const params = {
    HPOTerms: terms,
  };

  return axios
    .post(process.env.REACT_APP_RARECARE_API + "/analytics/hpo/process", params)
    .then((res) => {
      if (res.data.Error) {
        throw new Error("Error extracting HPO from ICD10");
      } else {
        return res.data.Payload;
      }
    })
    .catch((err) => {
      alert(err.message);
      return [];
    });
}

export async function HPOSearch(SearchTerm: string): Promise<any> {
  return axios.get("https://hpo.jax.org/api/hpo/search/?q=" + SearchTerm).then((res) => {
    return res.data;
  });
}
