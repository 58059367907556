import {
  Box,
  HStack,
  VStack,
  Text,
  Heading,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import { centimeterToFeet, getAge, gramToLibs } from "src/utils";
import { KebabMenuIcon } from "src/components/Icons";
import useDimensions from "src/hooks/use-dimensions";
import VitalsEditDrawer from "./VitalsEditDrawer";
import DiagnosisEditDrawer from "./DiagnosisEditDrawer";
import { MdSearch } from "react-icons/md";

export default function CaseSummary({ patient, patientCase }: any) {
  const {
    isOpen: editVitalsIsOpen,
    onOpen: openEditVitals,
    onClose: closeEditVitals,
  } = useDisclosure();
  const {
    isOpen: editDiagnosisIsOpen,
    onOpen: openEditDiagnosis,
    onClose: closeEditDiagnosis,
  } = useDisclosure();

  const { isMobile } = useDimensions();

  if (!patientCase || !patient) return null;

  const vitals = patient?.vitals || null;
  const medicationsArr = patientCase?.medications || null;
  const medicationsStr = medicationsArr?.map((item) => item.Medication).join(", ");

  const fullName = `${patient.name} ${patient.familyName}`;
  const userAge = patient.birthDate ? getAge(patient.birthDate) : null;

  return (
    <VStack
      flex="2"
      align="left"
      background="white"
      minWidth={["300px", "300px", "500px"]}
      maxWidth="100%"
      padding="40px"
      paddingTop="0px"
      boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
      borderRadius="8px"
    >
      <Flex direction="row-reverse">
        <Menu>
          <MenuButton as={IconButton} variant="ghost" icon={<KebabMenuIcon />} />
          <MenuList>
            <MenuItem onClick={openEditVitals}>Edit vitals</MenuItem>
            <MenuItem onClick={openEditDiagnosis}>Edit diagnosis</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <VStack align="left" gap="20px" flex="1">
        <Flex
          gap={["20px", "20px", "40px"]}
          direction={["column", "column", "row"]}
          marginBottom="20px"
        >
          <HStack spacing="24px">
            <Avatar size={"lg"} name={fullName} src={patient.avatarUrl} />
            <VStack align="left">
              <Heading fontSize="20px" lineHeight="24px" margin="0">
                {fullName}
              </Heading>
              <Text color="#496CA1" fontWeight="800">
                {userAge ? (
                  `${userAge} years old`
                ) : (
                  <Link to={`/patient/${patient.userId}/settings`}>
                    Add your birth date <EditIcon boxSize={4} />
                  </Link>
                )}
              </Text>
            </VStack>
          </HStack>
          <HStack>
            <HStack spacing="24px">
              <Box border="1px solid #B8BCD1" height="32px" />
              <Vital type="Blood" value={vitals?.bloodType ? vitals.bloodType : "N/A"} />
              <Vital
                type="Weight"
                value={vitals?.weight ? gramToLibs(vitals.weight) + " LBS" : "N/A"}
              />
              <Vital
                type="Height"
                value={vitals?.height ? centimeterToFeet(vitals.height) + " FT" : "N/A"}
              />
            </HStack>
            <Tooltip label="Edit vitals">
              <Button
                id="edit-vitals"
                as={EditIcon}
                boxSize={12}
                variant="ghost"
                onClick={openEditVitals}
              />
            </Tooltip>
          </HStack>
        </Flex>

        {patient.kitSerialNumber && (
          <HStack spacing="13px">
            <Heading as="h3" fontSize="14px" color="#496CA1" minWidth="100px">
              Kit Serial Number:
            </Heading>
            <Tooltip label={patient.kitSerialNumber}>
              <Text maxWidth="90%" isTruncated>
                {patient.kitSerialNumber}
              </Text>
            </Tooltip>
          </HStack>
        )}

        <HStack spacing="13px">
          <Heading as="h3" fontSize="14px" color="#496CA1" minWidth="100px">
            Diagnosis:
          </Heading>
          {patient?.mainDiagnosis ? (
            <>
              <Tooltip label={isMobile ? patient.mainDiagnosis : ""}>
                <Text isTruncated>{patient.mainDiagnosis} </Text>
              </Tooltip>
              <Tooltip label="Edit diagnosis">
                <Button
                  id="edit-diagnosis"
                  as={EditIcon}
                  boxSize={12}
                  variant="ghost"
                  margin="0px"
                  padding="0px"
                  width="16px"
                  height="16px"
                  onClick={openEditDiagnosis}
                />
              </Tooltip>
              <Tooltip label="Search physicians">
                <Link
                  to={`/patient/${patient.userId}/physician-search?diagnosis=${patient.mainDiagnosis}`}
                >
                  <Button
                    id="search-physician"
                    as={MdSearch}
                    boxSize={12}
                    variant="ghost"
                    margin="0px"
                    padding="0px"
                    width="16px"
                    height="16px"
                  />
                </Link>
              </Tooltip>
            </>
          ) : (
            <Button variant="ghost" onClick={openEditDiagnosis}>
              <Text color="#496CA1">
                Click to add diagnosis <EditIcon boxSize={4} />
              </Text>
            </Button>
          )}
        </HStack>

        <HStack spacing="13px">
          <Heading as="h3" fontSize="14px" color="#496CA1" minWidth="100px">
            Medications:
          </Heading>
          <Tooltip label={medicationsStr}>
            <Text maxWidth="90%" isTruncated>
              {medicationsStr || "Not provided"}
            </Text>
          </Tooltip>
        </HStack>

        {/* <HStack spacing="13px">
          <Heading as="h3" fontSize="14px" color="#496CA1" minWidth="100px">
            Providers:
          </Heading>
          <HStack>
            <Avatar size="sm" name="Brooklyn Simmons" src={""} />
            <Avatar size="sm" name="Jhon Doe" src={""} />
            <Avatar size="sm" name="Lucy Mesa" src={""} />
            <Avatar size="sm" name="Willy William" src={""} />
          </HStack>
        </HStack> */}
      </VStack>
      <VitalsEditDrawer
        patient={patient}
        initialValue={vitals || null}
        isOpen={editVitalsIsOpen}
        onClose={closeEditVitals}
      />
      <DiagnosisEditDrawer
        patient={patient}
        initialValue={patient.mainDiagnosis}
        isOpen={editDiagnosisIsOpen}
        onClose={closeEditDiagnosis}
      />
    </VStack>
  );
}

function Vital({ type, value }: { type: string; value: string | number }) {
  return (
    <VStack>
      <Text color="#496CA1" fontWeight="400" margin="0">
        {type}
      </Text>
      <Text color="#496CA1" fontWeight="800">
        {value}
      </Text>
    </VStack>
  );
}
