import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
  Badge,
  HStack,
} from "@chakra-ui/react";
import { MdSend } from "react-icons/md";
import { chatWithPrompt, checkChatResultStatus } from "../../services/doc-one/insight";
import Loader from "../Loader";

const ChatMessage = ({ message, isSent }) => {
  return (
    <Box w="full" maxW={isSent ? "80%" : "100%"} marginLeft={"auto"}>
      <Flex alignItems="center" mb={2}>
        <Box
          p={2}
          rounded="lg"
          display="inline-block"
          bgColor={isSent ? "blue.600" : "gray.300"}
          color={isSent ? "white" : "gray.600"}
          borderRadius="4"
          whiteSpace="pre-line"
          marginLeft={isSent ? "auto" : "none"}
        >
          {message}
        </Box>
      </Flex>
    </Box>
  );
};

const predefinedMessages = [
  {
    role: "user",
    label: "Layman's terms",
    content:
      "Write a simple review in plain language for a lay person who does not understand medical jargon. Use no more than 100 words.",
  },
  {
    role: "user",
    label: "Articles summary",
    content:
      "Please summarize scientific text. Sometimes more than one articles will be provided, in which case you will summarize them one by one. Summary should be concise, no more than 50 words per article. Summary should only include what is explicitly mentioned in the text. Focus on the main result and the overall conclusion.",
  },
  {
    role: "user",
    label: "Treatment Insights",
    content:
      "Please provide a comprehensive breakdown of the medical treatments discussed in the scientific text. Your report should exclusively include treatments utilized in human patients. Please do not include any information regarding diagnostic methods or any other facts mentioned in the text.",
  },
  {
    role: "user",
    label: "Diagnostic Insights",
    content:
      "Please provide a comprehensive breakdown of the diagnostic procedures discussed in the scientific text. Your report should exclusively include diagnostic procedures utilized in clinical practice on human patients. Please do not include any information regarding treatments, methods used in basic academic research, or any other facts mentioned in the text.",
  },

  {
    role: "user",
    label: "In-Depth review",
    content:
      "Write a review of the scientific text. Please provide a brief analysis, including notable findings, and conclusions.",
  },
  {
    role: "user",
    label: "Research methods",
    content:
      "Please summarize the research methods mentioned in the text. Your summary should include specific methodologies and techniques utilized in the study. Please focus on the research methods only and exclude any information regarding the results or conclusions of the study.",
  },
  {
    role: "user",
    label: "News format",
    content:
      "Write a brief news in the news language syle about the articles you found for me. Use no more than 100 words.",
  },
];

const Chat = ({ articles }) => {
  const toast = useToast();
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [history, setHistory] = useState([
    {
      role: "user",
      content: "Hi!",
    },
    {
      role: "assistant",
      content:
        "Hi, I’m your AI assistant. I’m here to help you find insights from your research. What would you like to me to help you with today? Let’s explore this research together!",
    },
    // {
    //   role: "system",
    //   content:
    //     "Search results: \n" +
    //     articles
    //       .map((article) => {
    //         const { url, pmid, title, abstract } = article;
    //         return `URL: ${url}, PMID: ${pmid}, Title: ${title}, Abstract: ${abstract}`;
    //       })
    //       .join("\n"),
    // },
  ]);

  if (articles.length && !messages.length) {
    setMessages([
      {
        text: "Hi, I’m your AI assistant. I’m here to help you find insights from your research. What would you like to me to help you with today? Let’s explore this research together!",
        isSent: false,
      },
    ]);
  }

  const chatContainerRef = React.useRef(null);

  const sendMessage = async (message, label) => {
    try {
      setLoading(true);
      setMessages((prev) => [...prev, { text: label || message, isSent: true }]);
      const chat = await chatWithPrompt({
        prompt: message,
        articles: articles.filter((article) => article.selected),
        history,
      });

      let chatResultStatus = "waiting";
      let chatResult = null;

      while (chatResultStatus === "waiting") {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        chatResult = await checkChatResultStatus({
          chatKey: chat.key,
        });
        chatResultStatus = chatResult.status;
      }

      if (chatResultStatus === "finished" && chatResult) {
        setHistory(chatResult.result.history);
        setMessages((prev) => [
          ...prev,
          {
            text: chatResult.result.response,
            isSent: false,
          },
        ]);
        setPrompt("");
      }

      toast({
        title: "Message sent!",
        description: "Your message has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error sending your message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    sendMessage(prompt);
  };

  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages.length, loading]);

  if (!articles.length && !history.length > 2) {
    return null;
  }

  return (
    <Flex
      flex={1}
      p={2}
      sm={{ p: 6 }}
      justifyContent="space-between"
      flexDirection="column"
      h="90vh"
      border="1px solid lightgray"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={3}
        borderBottom="2px"
        borderColor="gray.200"
      >
        <Box fontSize="2xl" mt={1} color="gray.700">
          Get Insight
        </Box>
      </Flex>

      <Box
        ref={chatContainerRef}
        id="messages"
        flex={1}
        flexDirection="column"
        p={3}
        overflowY="auto"
      >
        {messages.map((message, index) => (
          <ChatMessage key={index} message={message.text} isSent={message.isSent} />
        ))}
        {loading && <Loader loading />}
      </Box>

      <Box>
        <HStack align="left" flexWrap="wrap" mb="2" gap="2" padding="0px 8px">
          {predefinedMessages.map((message, index) => (
            <Badge
              key={index}
              border="1px solid dodgerblue"
              padding="2"
              _hover={{
                cursor: "pointer",
                backgroundColor: "lightblue",
              }}
              onClick={() => {
                sendMessage(message.content, message.label);
              }}
            >
              {message.label}
            </Badge>
          ))}
        </HStack>
        <Flex
          as="form"
          onSubmit={handleSendMessage}
          borderTop="2px"
          borderColor="gray.200"
          p={4}
          mb={2}
          sm={{ mb: 0 }}
        >
          <InputGroup w="full">
            <Input
              value={prompt}
              type="text"
              placeholder="Write your message here..."
              color="gray.600"
              focusBorderColor="blue.500"
              _placeholder={{ color: "gray.600" }}
              bg="gray.200"
              rounded="md"
              onChange={(e) => setPrompt(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                icon={<MdSend />}
                type="submit"
                aria-label="chat submit button"
                backgroundColor="transparent"
                h={9}
                w={9}
                rounded="full"
                transition="duration: 500ms"
                color="gray.500"
                _hover={{ bgColor: "gray.300" }}
                focus="outline-none"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Chat;
