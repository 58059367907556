import {
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Image,
  Stack,
  Text,
  useToast,
  FormHelperText,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import * as React from "react";
import { useAuth } from "../contexts/auth.context";

const ForgotPasswordForm = () => {
  const [username, setUsername] = React.useState("");
  const [passwordResetEmailSent, setPasswordResetEmailSent] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const toast = useToast();
  const { onForgotPasswordClick } = useAuth();

  const handlePasswordReset: React.FormEventHandler<HTMLDivElement> = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const result = await onForgotPasswordClick(username);
      if (result.error) throw result.error;
      setPasswordResetEmailSent(true);
      setIsLoading(false);
    } catch (error: any) {
      toast({
        position: "top-right",
        title: error.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (passwordResetEmailSent) {
    return <ResetPasswordForm username={username} />;
  }

  return (
    <Container maxW="md" py={{ base: "12", md: "24" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Image src={"../TMA_Logo.png"} />
          </Stack>
        </Stack>
        <Stack as="form" spacing="6" onSubmit={handlePasswordReset}>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              isDisabled={isLoading}
              id="email"
              placeholder="Enter your email"
              type="email"
              required
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormControl>
          <HStack justifyContent="flex-end">
            <Button
              type="button"
              variant="ghost"
              isDisabled={isLoading}
              colorScheme="blue"
              onClick={() => window.location.replace("/")}
            >
              Cancel
            </Button>
            <Button type="submit" isDisabled={isLoading} colorScheme="blue">
              Reset password
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </Container>
  );
};

const ResetPasswordForm = ({ username }: { username: string }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [repeatNewPassword, setRepeatNewPassword] = React.useState("");
  const [resetCompleted, setResetCompleted] = React.useState(false);

  const passwordsMismatch = newPassword !== repeatNewPassword;

  const toast = useToast();
  const { resetPassword } = useAuth();

  if (resetCompleted) {
    return (
      <Container>
        <Stack spacing="8">
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Image src={"../TMA_Logo.png"} />
          </Stack>

          <Alert status="success">
            <AlertIcon />
            Password reset completed successfully!
          </Alert>

          <Button colorScheme="blue" onClick={() => window.location.replace("/login")}>
            Go to login
          </Button>
        </Stack>
      </Container>
    );
  }

  const handlePasswordReset = async () => {
    if (!code) {
      return toast({
        position: "top-right",
        title: "Error! Please provide the code received by email",
        status: "error",
      });
    }

    if (newPassword !== repeatNewPassword) {
      return toast({
        position: "top-right",
        title: "Error! 'new password' & 'repeat new password' are not equal.",
        status: "error",
      });
    }

    try {
      setIsLoading(true);
      const result = await resetPassword(username, code, newPassword);

      if (result.error) throw result.error;

      setResetCompleted(true);
      toast({
        position: "top-right",
        title: "Password reset completed!",
        status: "success",
      });
    } catch (error: any) {
      toast({
        position: "top-right",
        title: error.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="md" py={{ base: "12", md: "24" }}>
      <Stack spacing="8">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Image src={"../TMA_Logo.png"} />
        </Stack>

        <Alert status="info">
          <AlertIcon />
          check your email to get the&nbsp;<Text fontWeight="bold"> reset code</Text>.
        </Alert>
        <Stack spacing="4">
          <FormControl>
            <FormLabel htmlFor="code">Password reset code</FormLabel>
            <Input
              value={code}
              isDisabled={isLoading}
              type="text"
              id="code"
              name="code"
              autoComplete="reset-code"
              placeholder="Enter the code"
              onChange={(e) => setCode(e.target.value)}
              required
            />
            <FormHelperText color="brand.gray2">
              <em>Enter the code received by email.</em>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="new_password">New password</FormLabel>
            <Input
              value={newPassword}
              isDisabled={isLoading}
              type="password"
              id="new_password"
              name="newPassword"
              autoComplete="new-password"
              placeholder="New password"
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="repeat_new_password">Repeat new password</FormLabel>
            <Input
              value={repeatNewPassword}
              isDisabled={isLoading}
              type="password"
              id="repeat_new_password"
              name="repeatNewPassword"
              placeholder="repeat new password"
              autoComplete="repeat-new-password"
              isInvalid={passwordsMismatch}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
              required
            />
            {passwordsMismatch ? (
              <FormHelperText color="red.500">
                <em>Should match the 'New password' field</em>
              </FormHelperText>
            ) : null}
          </FormControl>
          <HStack justifyContent="flex-end">
            <Button
              type="button"
              variant="ghost"
              isDisabled={isLoading}
              colorScheme="blue"
              onClick={() => window.location.replace("/")}
            >
              Cancel
            </Button>
            <Button type="button" onClick={handlePasswordReset} isDisabled={isLoading} colorScheme="blue">
              Reset password
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ForgotPasswordForm;
