import { PDFDocument, PDFPage, rgb, RGB } from "pdf-lib";
import { LabelItemObject, LabelsObject } from "src/types/file-labels.types";

export const pdfLabeler = async (label: LabelItemObject, pages: PDFPage[], color: RGB) => {
  const pageNum = label.Page;
  const page = pages[pageNum - 1];

  const { width, height } = page.getSize();

  if (label.Geometry) {
    let boundingBox = label.Geometry.BoundingBox;

    page.drawRectangle({
      x: width * boundingBox.Left,
      y: height * (1 - boundingBox.Top - boundingBox.Height),
      width: width * boundingBox.Width,
      height: height * boundingBox.Height,
      color: color,
      borderWidth: 1,
      opacity: 0.5,
    });
  }
};

export async function modifyPDF(pdfBuffer: ArrayBuffer, labels: LabelsObject | null) {
  const pdfDoc = await PDFDocument.load(pdfBuffer);
  const pages = pdfDoc.getPages();

  labels?.medications?.forEach((label) => {
    pdfLabeler(label, pages, rgb(0.0, 0, 0.95));
  });

  labels?.diseases?.forEach((label) => {
    pdfLabeler(label, pages, rgb(0.95, 0.1, 0.1));
  });

  labels?.diagnosis?.forEach((label) => {
    pdfLabeler(label, pages, rgb(0.1, 0.95, 0.1));
  });

  const pdfBytes = await pdfDoc.save();
  return { pdfBytes, pages };
}
