import React from "react";
// import { Chart } from "react-google-charts";
import ReactApexChart from "react-apexcharts";
import MedicationDrawer from "./MedicationDrawer";
import { useDisclosure } from "@chakra-ui/react";

type PropsTable = {
  meds: any;
};

const MedicationTimeline: React.FC<PropsTable> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMedication, setSelectedMedication] = React.useState<string>("");

  let medications = props.meds || [];

  const options = {
    chart: {
      height: 350,
      type: "rangeBar",
      events: {
        dataPointSelection: (event: any, chartContext: any, config: any) => {
          const selectedItem = config.dataPointIndex;
          const medName = config.w.globals.labels[selectedItem];
          setSelectedMedication(medName);
          onOpen();
          // Perform your custom action here
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any, opts: any) {
        var label = opts.w.globals.labels[opts.dataPointIndex];
        // var a = moment(val[0])
        // var b = moment(val[1])
        // var diff = b.diff(a, 'days')
        return label;
      },
      style: {
        colors: ["#f3f4f5", "#fff"],
      },
    },
  };

  const rows =
    medications?.map((med: any) => {
      const res = med.Date?.split("T");
      // let medDate: Date = new Date(res[0]);
      // let nowDate: Date = new Date()
      //

      return {
        x: med.Medication,
        y: [new Date(res[0]).getTime(), new Date().getTime()],
      };
    }) || [];

  const series = [
    {
      data: rows,
    },
  ];

  // const data = [columns, ...rows]
  // return <Chart chartType="Timeline" data={data} width="100%" height="800px" />;

  //This function if exclusively to be able to use the @ts-ignore directive
  const makeChart = () => {
    // @ts-ignore
    return <ReactApexChart options={options} series={series} type="rangeBar" height="350px" />;
  };

  // @ts-ignore
  return (
    <>
      <MedicationDrawer
        DrugName={selectedMedication}
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
      />
      {makeChart()}
    </>
  );
};

export default MedicationTimeline;
