export function paginate(data: any[], { perPage = 5 }: { perPage: number }) {
  const paginatedData: any = [];
  let pageData: any[] = [];

  for (const item of data) {
    pageData.push(item);

    if (pageData.length === perPage) {
      paginatedData.push(pageData);
      pageData = [];
    }
  }

  if (pageData.length) {
    paginatedData.push(pageData);
  }

  return paginatedData;
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // Result contains the base64-encoded data as a string.
      const result = reader.result as string;
      resolve(result);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    // Read the file as a Data URL (base64).
    reader.readAsDataURL(file);
  });
}

export function roundToTwoDecimal(num: number) {
  return Number(Math.round(Number(num + "e+2")) + "e-2");
}

export function roundToOneDecimal(num: number) {
  return Number(Math.round(Number(num + "e+1")) + "e-1");
}

export function meterToCentimeter(num: number) {
  return num * 100;
}

export function centimeterToMeter(num: number) {
  return num / 100;
}

export function centimeterToFeet(num: number) {
  return roundToOneDecimal(centimeterToMeter(num) * 3.281);
}

export function feetToCentimeter(num: number) {
  return roundToOneDecimal(num * 30.48);
}

export function kilogramToGram(num: number) {
  return num * 1000;
}

export function gramToKilogram(num: number) {
  return num / 1000;
}

export function KilogramToLibs(num: number) {
  return roundToOneDecimal(num * 2.205);
}

export function gramToLibs(num: number) {
  return KilogramToLibs(gramToKilogram(num));
}

export function LibsToGram(num: number) {
  return roundToOneDecimal(num * 453.6);
}

export function getAge(dateString: string): number {
  const birthDate = new Date(dateString);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export function getHours(date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  let minutesString = minutes < 10 ? "0" + minutes : minutes;

  let timeString = `${hours}:${minutesString} ${period}`;

  return timeString;
}

export function downloadFile({ fileName, data }: { fileName: string; data: any }) {
  const anchorTagElement = document.createElement("a");
  anchorTagElement.style.display = "none";
  document.body.appendChild(anchorTagElement);

  const blobFile = new Blob([data]);
  const url = window.URL.createObjectURL(blobFile);

  anchorTagElement.href = url;
  anchorTagElement.download = fileName;
  anchorTagElement.click();

  window.URL.revokeObjectURL(url);
}

export function debounce(func: Function, delay: number): (...args: any[]) => void {
  let timerId: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function convertBytesToKBorMB(bytes: number): string {
  if (bytes < 1024 * 1024) {
    const kilobytes = (bytes / 1024).toFixed(2);
    return `${kilobytes}KB`;
  } else {
    const megabytes = (bytes / (1024 * 1024)).toFixed(2);
    return `${megabytes}MB`;
  }
}

export const sanitizeFilename = (filename: string) => {
  return filename.replace(/[^a-zA-Z0-9.]/g, "_");
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
