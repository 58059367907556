import React, { ChangeEvent, useEffect, useState } from "react";

import {
  Alert,
  AlertIcon,
  HStack,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import {
  extractHPOFromICD10,
  extractICD10FromDescription,
} from "../../../services/rare-care/Analytics";
import ActionButton from "./ActionButton";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { FiXCircle } from "react-icons/fi";
import { MdOutlineVerified } from "react-icons/md";
import { usePatientContext } from "src/contexts/patient.context";
import { updateCase, uploadFileToCase } from "src/services/patient";
import FileUploadInput from "../FileUploadInput";
import { getBase64, sanitizeFilename } from "src/utils";

const API_ENDPOINT = "https://journey-api.tma.healthcare";

const ShortMedicalRecordsProcessorDrawer: React.FC = () => {
  const toast = useToast({ position: "top-right" });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<Record<string, boolean>>({});
  const [filename, setFilename] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { caseData, setCaseData, refetchPatient } = usePatientContext();

  const handleFileProcessing = async () => {
    if (!selectedFile) return;

    const file = selectedFile;
    const pdf_blob = await getBase64(file);

    try {
      setLoading(true);
      const res = await axios.post(API_ENDPOINT + "/uploadPDF", {
        FileName: sanitizeFilename(file.name),
        PDF: pdf_blob.replace(/^data:application\/pdf;base64,/, ""),
      });

      if (res.data.Error) {
        return toast({ status: "error", description: "Error uploading PDF" + res.data.Body });
      }

      uploadFileToCase({
        fileBase64: pdf_blob,
        fileName: file.name,
        fileType: "medical_record",
        caseId: caseData?.id,
      })
        .then((response) => {
          refetchPatient();
          setCaseData((prev) => ({ ...prev, files: response.data.files }));
        })
        .catch(() => {
          toast({ status: "error", description: "Error! Failed to upload file to case." });
        });

      const processRes = await processPDF();
      const translateRes = await translatePDF(processRes);
      const extractCasesRes = await extractCaseDescription(translateRes);
      const comprehendRes = await comprehendEMR(translateRes);
      const processTimelineRes = await processTimeline(comprehendRes);
      await uploadTimeline(processTimelineRes);
      const medicationsRes = await getMedications(processTimelineRes);
      await uploadMedications(medicationsRes);
      const icd10Res = await getICD10Codes(extractCasesRes);
      await uploadICD10Codes(icd10Res);
      const getHPORes = await getHPOCodes(icd10Res);
      await uploadHPOCodes(getHPORes);

      setStatus((prevStatus) => ({ ...prevStatus, processCompleted: true }));
      setLoading(false);
      setFilename("");
      setSelectedFile(null);
      // refreshCase();
    } catch (err: any) {
      toast({ status: "error", description: "Error processing file  " + err.message });
      setStatus((prevStatus) => ({
        ...prevStatus,
        processCompleted: false,
        processingError: true,
        error: err.message,
      }));
      setLoading(false);
    }
  };

  const processPDF = async () => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, processing: true }));
      const res = await axios.post(API_ENDPOINT + "/processPDFV2", { FileName: filename });
      setStatus((prevStatus) => ({ ...prevStatus, processing: false, processed: true }));
      return res.data;
    } catch (err: any) {
      setStatus((prevStatus) => ({ ...prevStatus, processing: false, processed: false }));
      throw new Error("PDF Text extraction Failed " + err.message);
    }
  };

  const translatePDF = async (tags: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, translating: true }));
      const res = await axios.post(API_ENDPOINT + "/translateTags", { Tags: tags });
      setStatus((prevStatus) => ({ ...prevStatus, translating: false, translated: true }));
      return res.data;
    } catch (err: any) {
      setStatus((prevStatus) => ({ ...prevStatus, translating: false, translated: false }));
      throw new Error("Tag translation Failed " + err.message);
    }
  };

  const extractCaseDescription = async (tags: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, extractingDescription: true }));
      const res = await axios.post(API_ENDPOINT + "/extractCaseDescription", { Tags: tags });

      setCaseData((prevCaseData) => ({
        ...prevCaseData,
        description: res.data.join(),
      }));
      setStatus((prevStatus) => ({
        ...prevStatus,
        extractingDescription: false,
        descriptionExtracted: true,
      }));
      return res.data;
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        extractingDescription: false,
        descriptionExtracted: false,
      }));
      throw new Error("Case Description Extraction Failed  " + err.message);
    }
  };

  const comprehendEMR = async (tags: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, comprehendingEmr: true }));
      const res = await axios.post(API_ENDPOINT + "/comprehendEMRv2", { Tags: tags });
      setStatus((prevStatus) => ({
        ...prevStatus,
        comprehendingEmr: false,
        emrComprehended: true,
      }));
      return res.data;
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        comprehendingEmr: false,
        emrComprehended: false,
      }));
      throw new Error("PDF Comprehension Failed  " + err.message);
    }
  };

  const processTimeline = async (comprehension: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, processingTimeline: true }));
      const res = await axios.post(API_ENDPOINT + "/processComprehendResultv2", {
        DatedComprehend: comprehension,
      });
      setStatus((prevStatus) => ({
        ...prevStatus,
        processingTimeline: false,
        timelineProcessed: true,
      }));
      return res.data;
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        processingTimeline: false,
        timelineProcessed: false,
      }));
      throw new Error("Timeline Processing Failed  " + err.message);
    }
  };

  const uploadTimeline = async (timeline: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, updatingTimeline: true }));
      // await RareCare?.updateCaseTimeline(timeline);
      setCaseData((prevCaseData) => ({
        ...prevCaseData,
        timeline,
      }));
      setStatus((prevStatus) => ({
        ...prevStatus,
        updatingTimeline: false,
        timelineUpdated: true,
      }));
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        updatingTimeline: false,
        timelineUpdated: false,
      }));
      throw new Error("Timeline Upload Failed  " + err.message);
    }
  };

  const getMedications = async (timeline: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, completingMedications: true }));
      const res = await axios.post(API_ENDPOINT + "/processRxResultv2", { Timeline: timeline });
      setStatus((prevStatus) => ({
        ...prevStatus,
        completingMedications: false,
        medicationsCompleted: true,
      }));
      return res.data;
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        completingMedications: false,
        medicationsCompleted: false,
      }));
      throw new Error("Medication Analysis Failed " + err.message);
    }
  };

  const uploadMedications = async (medications: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, uploadingMedications: true }));
      // await RareCare?.updateCaseMedications(medications);
      setCaseData((prevCaseData) => ({
        ...prevCaseData,
        medications,
      }));
      setStatus((prevStatus) => ({
        ...prevStatus,
        uploadingMedications: false,
        medicationsUploaded: true,
      }));
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        uploadingMedications: false,
        medicationsUploaded: false,
      }));
      throw new Error("Medication Upload Failed " + err.message);
    }
  };

  const getICD10Codes = async (caseDescription: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, completingICD10Codes: true }));
      const res = await extractICD10FromDescription(caseDescription);
      setStatus((prevStatus) => ({
        ...prevStatus,
        completingICD10Codes: false,
        ICD10CodesCompleted: true,
      }));
      return res;
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        completingICD10Codes: false,
        ICD10CodesCompleted: false,
      }));
      throw new Error("ICD10 Extraction Failed " + err.message);
    }
  };

  const uploadICD10Codes = async (ICD10Codes: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, uploadingICD10Codes: true }));
      // await RareCare?.updateCaseICD10Codes(ICD10Codes);
      setCaseData((prevCaseData) => ({
        ...prevCaseData,
        icd10Codes: ICD10Codes,
      }));
      setStatus((prevStatus) => ({
        ...prevStatus,
        uploadingICD10Codes: false,
        ICD10CodesUploaded: true,
      }));
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        uploadingICD10Codes: false,
        ICD10CodesUploaded: false,
      }));
      throw new Error("ICD10 Upload Failed " + err.message);
    }
  };

  const getHPOCodes = async (ICD10Codes: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, completingHPOCodes: true }));
      const res = await extractHPOFromICD10(ICD10Codes);
      setStatus((prevStatus) => ({
        ...prevStatus,
        completingHPOCodes: false,
        HPOCodesCompleted: true,
      }));
      return res;
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        completingHPOCodes: false,
        HPOCodesCompleted: false,
      }));
      throw new Error("HPO extraction Failed " + err.message);
    }
  };

  const uploadHPOCodes = async (HPOCodes: any) => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, uploadingHPOCodes: true }));
      // await RareCare?.updateCaseHPOCodes(HPOCodes);
      setCaseData((prevCaseData) => ({
        ...prevCaseData,
        hpoCodes: HPOCodes,
      }));

      setStatus((prevStatus) => ({
        ...prevStatus,
        uploadingHPOCodes: false,
        HPOCodesUploaded: true,
      }));
    } catch (err: any) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        uploadingHPOCodes: false,
        HPOCodesUploaded: false,
      }));
      throw new Error("HPO upload Failed " + err.message);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetState();
    const files = e.target.files || null;
    const file = files?.[0] || null;
    setFilename(sanitizeFilename(file?.name || ""));
    setSelectedFile(file);
  };

  const resetState = () => {
    setStatus({});
    setFilename("");
    setSelectedFile(null);
  };

  useEffect(() => {
    if (status.processCompleted && !status.caseUpdated) {
      updateCase({
        caseId: caseData.id,
        data: { ...caseData },
      })
        .then((response) => {
          setCaseData(response.data);
          setStatus((prevStatus) => ({ ...prevStatus, caseUpdated: true }));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [caseData, setCaseData, status]);

  return (
    <>
      <ActionButton title="Add Medical Record" onClick={onOpen} logoSrc="/frame-41.svg" />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          resetState();
          onClose();
        }}
        size="lg"
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Medical Record</DrawerHeader>

          <DrawerBody>
            <FileUploadInput
              disabled={loading}
              title="Select file to process"
              accept=".pdf"
              onInputClear={resetState}
              onChange={handleOnChange}
            />
            <Button
              onClick={handleFileProcessing}
              isDisabled={loading || !selectedFile}
              variant="solid"
              colorScheme="blue"
              width="100%"
              marginTop="2"
              marginBottom="4"
            >
              Process File
            </Button>

            <>
              {loading && (
                <Alert status="info" marginBottom="4">
                  <AlertIcon />
                  Processing file. That could take several minutes...
                  <Spinner size="md" />
                </Alert>
              )}
              <VStack align="left">
                {status.processing && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Uploading file...</Text>
                  </HStack>
                )}
                {status.processed && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> File uploaded successfully.</Text>
                  </HStack>
                )}

                {status.translating && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Translating file...</Text>
                  </HStack>
                )}
                {status.translated && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> File translated successfully.</Text>
                  </HStack>
                )}

                {status.extractingDescription && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Extracting description...</Text>
                  </HStack>
                )}
                {status.descriptionExtracted && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> Description extracted successfully.</Text>
                  </HStack>
                )}

                {status.comprehendingEmr && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Comprehending EMR...</Text>
                  </HStack>
                )}
                {status.emrComprehended && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> EMR comprehended successfully.</Text>
                  </HStack>
                )}

                {status.processingTimeline && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Processing timeline...</Text>
                  </HStack>
                )}
                {status.timelineProcessed && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> Timeline processed successfully.</Text>
                  </HStack>
                )}

                {status.updatingTimeline && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Updating timeline...</Text>
                  </HStack>
                )}
                {status.timelineUpdated && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> Timeline updated successfully.</Text>
                  </HStack>
                )}

                {status.uploadingMedications && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Uploading medications...</Text>
                  </HStack>
                )}
                {status.medicationsUploaded && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> Medications uploaded successfully.</Text>
                  </HStack>
                )}

                {status.completingICD10Codes && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Completing ICD10 codes...</Text>
                  </HStack>
                )}
                {status.ICD10CodesUploaded && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> ICD10 codes completed successfully.</Text>
                  </HStack>
                )}

                {status.uploadingICD10Codes && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Uploading ICD10 codes...</Text>
                  </HStack>
                )}
                {status.ICD10CodesUploaded && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> ICD10 codes uploaded successfully.</Text>
                  </HStack>
                )}

                {status.completingHPOCodes && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Completing HPO codes...</Text>
                  </HStack>
                )}
                {status.HPOCodesCompleted && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> HPO codes completed successfully.</Text>
                  </HStack>
                )}

                {status.uploadingHPOCodes && (
                  <HStack align="left">
                    <Spinner size="sm" /> <Text as="span"> Uploading HPO codes...</Text>
                  </HStack>
                )}
                {status.HPOCodesUploaded && (
                  <HStack align="left">
                    <MdOutlineVerified color="green" />{" "}
                    <Text as="span"> HPO codes uploaded successfully.</Text>
                  </HStack>
                )}

                {status.processingError && (
                  <HStack align="left">
                    <FiXCircle color="red" />
                    <Text as="span" color="red">
                      {status.error}
                    </Text>
                  </HStack>
                )}

                {status.processCompleted && (
                  <VStack align="left">
                    <Text as="span" fontWeight="bold">
                      🎉 All Done! 🎉
                    </Text>
                    <Button
                      variant="outline"
                      mr={3}
                      onClick={() => {
                        resetState();
                        onClose();
                      }}
                    >
                      OK
                    </Button>
                  </VStack>
                )}
              </VStack>
            </>
          </DrawerBody>
          <DrawerFooter>
            <Button
              isDisabled={loading}
              variant="outline"
              mr={3}
              onClick={() => {
                resetState();
                onClose();
              }}
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ShortMedicalRecordsProcessorDrawer;
